import { createContext, useState } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  return (
    <SessionContext.Provider value={{ sessionId, setSessionId, userEmail, setUserEmail }}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;

