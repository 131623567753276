import React from 'react';
import UploadVideo from '../pages/Upload'; // Adjust path if necessary
import './UploadModal.css'; // Adjust the path if necessary


const UploadModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? 'show' : ''}`}>
      <div className="modal-content">
        <span className="upload-close-button" onClick={onClose}>&times;</span>
        <UploadVideo onClose={onClose} /> {/* Pass onClose to UploadVideo */}
      </div>
    </div>
  );
};

export default UploadModal;
