import React, { useState, useEffect, useRef, useContext } from "react"; // Include useRef
import { useLocation } from "react-router-dom";
import {
  getClips,
  getChapters,
  getURL,
  downloadClip,
  searchChapters as apiSearchChapters,
  checkTikTokRegistration,
  checkYoutubeRegistration,
} from "../services/api"; // Import getUrl
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.min.css';

import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import VideoPlayer from "../components/VideoPlayer";
import { Range } from "react-range";
import "./GeneratedClips.css";
import Confetti from "react-confetti";
import TiktokUpload from "../components/TiktokUpload";
import AuthContext from "../AuthContext";
import { getAccountInfo } from "../services/api";
import YoutubeUpload from "../components/YoutubeUpload";

const formatTime = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

const truncate = (input, length) => {
  if (input.length > length) {
    return input.substring(0, length) + "...";
  }
  return input;
};


const fetchUserActions = async (userEmail) => {
  const db = getFirestore();
  const oneDayAgo = new Date();
  oneDayAgo.setHours(oneDayAgo.getHours() - 24);

  const q = query(
    collection(db, 'analytics'),
    where('email', '==', userEmail),
    where('time', '>=', oneDayAgo)
  );
  
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map(doc => doc.data());
};


const GeneratedClips = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [accountInfo, setAccountInfo] = useState(null);
  const [usageLimit, setUsageLimit] = useState(0);
  const location = useLocation();
  const videofile = location.pathname.split("/clips/")[1];
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userActions, setUserActions] = useState([]);

  const presetSearches = ["funny", "controversial", "informational"];
  const [isStarred, setIsStarred] = useState(false);

  const toggleStar = () => {
    setIsStarred(!isStarred);
  };
  const [clips, setClips] = useState([]);
  const [selectedClip, setSelectedClip] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isTikTokConnected, setIsTikTokConnected] = useState(null);
  const [isYoutubeConnected, setIsYoutubeConnected] = useState(null);

  const [trim, setTrim] = useState([0, 0]);

  const [showTiktokUpload, setShowTiktokUpload] = useState(false);
  const [showYoutubeUpload, setShowYoutubeUpload] = useState(false);
  const [userEmail, setUserEmail] = useState(null);


  // Function to sort clips based on starred status
  const sortClipsByStarred = (clipsArray) => {
    return clipsArray.sort((a, b) => (b.isStarred ? 1 : 0) - (a.isStarred ? 1 : 0));
  };
  const [subscriptionType, setSubscriptionType] = useState(null);

  const [isBannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    if (subscriptionType === 'free') {
      setBannerVisible(true);
    } else {
      setBannerVisible(false);
    }
  }, [subscriptionType]);


  const searchChapters = async () => {
    setIsSearching(true);

    const response = await apiSearchChapters(videofile, searchTerm);

    if (response.result === "success") {
      const searchChaptersData = response.chapters.map((chapter, index) => {
        let isStarred = false;
        // Check if this clip was previously starred
        const existingClip = clips.find(c => c.chapter.start === chapter.start && c.chapter.end === chapter.end);
        if (existingClip) {
          isStarred = existingClip.isStarred;
        }
        return {
          chapter: {
            caption: chapter.caption,
            duration: chapter.duration,
            end: chapter.end,
            start: chapter.start,
            text: chapter.text,
          },
          title: chapter.title,
          caption: chapter.caption,
          isStarred: isStarred
        };
      });
      setClips(sortClipsByStarred(searchChaptersData));  // Sort clips after setting them
    } else {
      console.error(response.message);
    }
    console.log(response);

    setIsSearching(false);
  };

useEffect(() => {
  const fetchData = async () => {
    if (videofile) {
      console.log(videofile, "videofile");
      console.log(selectedClip, "selectedClip");
      const videoUrlData = await getURL(videofile);
      const chaptersData = await getChapters(videofile);
      setVideoUrl(videoUrlData.url);
      if (chaptersData.result === "success") {
        const clipsData = chaptersData.chapters.map((chapter, index) => {
          return {
            chapter,
            url: videoUrlData.url,
            title: chapter.title,
            caption: chapter.caption,
            isStarred: false  // Set isStarred to false initially
          };
        });
        setClips(clipsData);
        setSelectedClip(clipsData[0])
        console.log(clipsData);
      } else {
        console.error(chaptersData.message);
      }
    }
  };
  fetchData();
}, [videofile]);

useEffect(() => {
  if (isLoggedIn) {
    (async () => {
      const response = await getAccountInfo();
      if (response.result === "success") {
        console.log("Account Info:", response.accountInfo);
        setAccountInfo(response.accountInfo);
        setUserEmail(response.accountInfo.email);

        // Fetch the user's upload actions based on the email from accountInfo
        const userEmail = response.accountInfo.email;
        const actions = await fetchUserActions(userEmail);
        setUserActions(actions);

        switch (response.accountInfo.subscription_type) {
          case "free":
            setUsageLimit(60);
            setSubscriptionType("free");
            break;
          case "prod_O8wYwjy7Op6eSU":
            setUsageLimit(240);
            setSubscriptionType("prod_O8wYwjy7Op6eSU");
            break;
          case "prod_O8wcbG8gumzDs1":
            setUsageLimit(1000);
            setSubscriptionType("prod_O8wcbG8gumzDs1");
            break;
          default:
            setUsageLimit(0);
        }
      }
    })();
  }
}, [isLoggedIn]);

  const handlePresetSearch = async (preset) => {
    setSearchTerm(preset);
    await searchChapters();
  };

  const handleTitleChange = (e, clip) => {
    const newClip = { ...clip, title: e.target.value };
    const newClips = clips.map((c) => (c === clip ? newClip : c));
    setClips(newClips);
    setSelectedClip(newClip);

    setTrim([newClip.chapter.start / 1000, newClip.chapter.end / 1000]);
  };

  const handleCaptionChange = (e, clip) => {
    const newClip = { ...clip, caption: e.target.value };
    const newClips = clips.map((c) => (c === clip ? newClip : c));
    setClips(newClips);
    setSelectedClip(newClip);

    setTrim([newClip.chapter.start / 1000, newClip.chapter.end / 1000]);
  };

  const handleTiktokPostButton = () => {
    setShowYoutubeUpload(false);
    setShowTiktokUpload(true);

  };

  const handleYoutubePostButton = () => {
    setShowTiktokUpload(false);
    setShowYoutubeUpload(true);

  };

  useEffect(() => {
    const fetchData = async () => {
      if (videofile) {
        console.log(videofile, "videofile");
        console.log(selectedClip, "selectedClip");
        const videoUrlData = await getURL(videofile);
        const chaptersData = await getChapters(videofile);
        setVideoUrl(videoUrlData.url);
        if (chaptersData.result === "success") {
          const clipsData = chaptersData.chapters.map((chapter, index) => {
            return {
              chapter,
              url: videoUrlData.url,
              title: chapter.title,
              caption: chapter.caption,
              isStarred: false  // Add this line
            };
          });
          setClips(clipsData);
          setSelectedClip(clipsData[0])
          console.log(clipsData);
        } else {
          console.error(chaptersData.message);
        }
      }
    };
    fetchData();
  }, [videofile]);

  useEffect(() => {
    if (selectedClip && videoRef.current) {
      videoRef.current.currentTime = selectedClip.chapter.start / 1000;
      videoRef.current.ontimeupdate = () => {
        if (videoRef.current.currentTime >= selectedClip.chapter.end / 1000) {
          videoRef.current.pause();
        }
      };
    }
  }, [selectedClip]);

  useEffect(() => {
    const checkTiktokConnection = async () => {
      const response = await checkTikTokRegistration();
      setIsTikTokConnected(response.tokenBool);
      console.log(response);
    };

    checkTiktokConnection();
  }, []);

  useEffect(() => {
    const checkYoutubeConnection = async () => {
      const response = await checkYoutubeRegistration();
      console.warn(response, 'token boool');
      setIsYoutubeConnected(response.tokenBool);
      console.log(response);
    };

    checkYoutubeConnection();
  }, []);

  
  // State to keep track of the user's upload actions

  // Fetch the user's actions whenever the component mounts or userEmail changes
  useEffect(() => {
    const fetchAndSetUserActions = async () => {
      const actions = await fetchUserActions(userEmail);
      setUserActions(actions);
    };
    fetchAndSetUserActions();
  }, [userEmail]);

  const uploadCount = userActions.reduce((count, action) => {
    return action.event === 'post_tiktok' || action.event === 'post_youtube' ? count + 1 : count;
  }, 0);
  

  // Decide the button's enabled/disabled state and tooltip
  const getButtonProps = (actionType) => {
    if (subscriptionType === 'free') {
      return { disabled: true, title: 'Subscription required for direct posting' };
    }
    if (subscriptionType === 'prod_O8wYwjy7Op6eSU' && uploadCount >= 10) {
      return { disabled: true, title: 'Sorry, you can only post 10 times in a 24hr window' };
    }
    if (subscriptionType === 'prod_O8wcbG8gumzDs1') {
      return { disabled: false };
    }
    return { disabled: false };
  };

  

  return (
    <>

      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          colors={["#420c68", "#800080", "#c19cdb", "#000000"]}
        />
      )}
      {isBannerVisible && (
        <div className="free-subscription-banner">
          Your free subscription only allows for the first 15 minutes of your video to be processed. 
          If you'd like to generate clips for the entirety of your video, please 
          <a href="/subscription"> Upgrade Here</a>.
          <button 
            className="close-banner-btn" 
            onClick={() => setBannerVisible(false)}
          >
            &times;
          </button>
        </div>
      )}
      <div className={`gen-clips ${isBannerVisible ? 'with-banner' : ''}`}>
        <div className="generated-clips-container">
          <div className="search-bar">
            <input
              type="text"
              className="search-input"
              placeholder="IntellectSearch..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="search-button" onClick={searchChapters}>
              <i className="fas fa-search"></i>
            </button>
          </div>
          <div className="search-tags">
            {["Funny", "Controversial", "Entertaining"].map((tag) => (
              <button
                key={tag}
                className="tag-button"
                onClick={() => {
                  setSearchTerm(tag);
                  searchChapters(); // Trigger the search function
                }}
              >
                {tag}
              </button>
            ))}
          </div>




          <div className="clips-grid">
            {isSearching ? (
              <div className="search-spinner">
                {" "}
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              clips
                .sort((a, b) => (b.isStarred ? 1 : 0) - (a.isStarred ? 1 : 0))
                .map((clip, index) => (
                  <div
                    key={index}
                    className={`clip-container ${selectedClip === clip ? "selected" : ""
                      }`}
                    onClick={(e) => {
                      if (e.target.classList.contains('clip-star')) return;
                      setSelectedClip(clip);
                    }}
                  >
                    {/* Add the star icon here */}
                    <i
                      className={`fa ${clip.isStarred ? 'fa-star' : 'fa-star-o'} clip-star`}
                      aria-hidden="true"
                      onClick={(e) => {
                        e.stopPropagation();  // Prevent the clip selection when star is clicked
                        const updatedClips = [...clips];
                        updatedClips[index].isStarred = !updatedClips[index].isStarred;
                        setClips(prevClips => {
                          const newClips = [...prevClips];
                          newClips[index].isStarred = !newClips[index].isStarred;
                          return newClips;
                        });
                      }}
                    ></i>

                    <div className="clip-content">
                      <div className="clip-info">
                        <div className="clipName">
                          {clip.title
                            ? truncate(clip.title, 50)
                            : `Clip ${index + 1}`}
                        </div>
                      </div>
                      <div className="clip-details">
                        <p>{formatTime(clip.chapter.duration)}</p>
                        <p>{`${formatTime(clip.chapter.start)} - ${formatTime(
                          clip.chapter.end
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>

        </div>
        <div className="clip-editor">
          {!selectedClip ? (
            <div className="empty-editor">
              Select a clip on the left to start editing!
            </div>
          ) : (
            <div className="editor-content">
              <div className="video-player">
                <VideoPlayer
                  src={videoUrl}
                  start={selectedClip.chapter.start / 1000}
                  end={selectedClip.chapter.end / 1000}
                  trim={trim} // pass down the current trim
                  setTrim={setTrim} // pass down the function to update trim
                />
              </div>
              <div className="clip-options">

                <div className="option-group">
                  <label htmlFor="title">Title</label>
                  {/* Prefill the title input with selectedClip.title */}
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="title"
                    value={selectedClip.title}
                    onChange={(e) => handleTitleChange(e, selectedClip)}
                  />
                </div>
                <div className="option-group">
                  <label htmlFor="caption">Caption</label>
                  {/* Prefill the caption input with selectedClip.caption */}
                  <textarea
                    id="caption"
                    name="caption"
                    rows="3"
                    cols="100"
                    className="caption"
                    value={selectedClip.caption}
                    onChange={(e) => handleCaptionChange(e, selectedClip)}
                  />
                </div>
                <label htmlFor="caption">Sharing & Download</label>
                <div className="share-options">
                  {isDownloading ? <div className="spinner2"></div> : null}
                  <button
                    className="download-button"
                    onClick={async () => {
                      setIsDownloading(true);
                      await downloadClip(videoUrl, trim[0], trim[1]);
                      setIsDownloading(false);
                      setShowConfetti(true);
                      setTimeout(() => setShowConfetti(false), 5000); // hide confetti after 5 seconds
                    }}
                  >
                    {isDownloading ? <FontAwesomeIcon icon={faDownload} size="lg" /> : <FontAwesomeIcon icon={faDownload} size="lg" />}
                  </button>
                  <div>
                    {isYoutubeConnected === true ? (
                      <>
                        <button
                          {...getButtonProps('youtube')}
                          className="tiktok-button"
                          onClick={() => { handleYoutubePostButton(); }}
                        >
                          <FontAwesomeIcon icon={faYoutube} size="lg" />
                        </button>
                      </>
                    ) : (
                      <button
                        className="connect-tiktok-button"
                        onClick={() => {
                          window.location.href = "/connected-accounts";
                        }}
                      >
                        <FontAwesomeIcon icon={faYoutube} size="lg" />
                      </button>
                    )}
                  </div>
                  {/* Remove this button
<button className="tiktok-button"
onClick={() => {handleTiktokPostButton()}}>
  Post to TikTok
</button>
*/}
                  {isTikTokConnected === true ? (
                    <>
                      <button
                        {...getButtonProps('tiktok')}
                        className="tiktok-button"
                        onClick={() => { handleTiktokPostButton(); }}
                      >
                        <FontAwesomeIcon icon={faTiktok} size="lg" />
                      </button>
                    </>
                  ) : isTikTokConnected === false ? (
                    <button
                      className="connect-tiktok-button"
                      onClick={() => {
                        window.location.href = "/connected-accounts";
                      }}
                    >
                      <FontAwesomeIcon icon={faTiktok} size="lg" />
                    </button>
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
                <div className="upload-container">
                  <div className={`youtube-upload-container ${showYoutubeUpload ? 'active' : 'inactive'}`}>
                    {showYoutubeUpload && (
                      <YoutubeUpload
                        videourl={videoUrl}
                        start={trim[0]}
                        stop={trim[1]}
                        title={selectedClip.title}
                        description={selectedClip.caption}
                      />
                    )}
                  </div>
                  <div className={`tiktok-upload-container ${showTiktokUpload ? 'active' : 'inactive'}`}>
                    {showTiktokUpload && (
                      <TiktokUpload
                        videourl={videoUrl}
                        start={trim[0]}
                        stop={trim[1]}
                        title={selectedClip.caption}
                        videofile={videofile}  // passing down the filename
                      />

                    )}
                  </div>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default GeneratedClips;
