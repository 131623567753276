// Import axios at the top of your file
import axios from 'axios';

const CHATBOT_API = process.env.REACT_APP_CHATBOT_API_BASE_URL;
axios.defaults.withCredentials = true;

const getSessionIdFromLocalStorage = () => {
    return localStorage.getItem('session');
  };

// Then define the chat function
const chat = async (message, filename) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();  // Make sure this function is defined
    const response = await axios.post(
      `${CHATBOT_API}/api/chat`, // Your API endpoint
      {
        message: message
      },
      {
        headers: {
          'session': sessionId,
          'file': filename,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { result: 'error', message: 'An error occurred while sending the message. This error is occuring within the API' };
  }
};

export { chat };