import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import Sidebar from '../components/Sidebar';
import { getAccountInfo } from '../services/api';
import { logout } from '../services/auth';
import './Usage.css'; // Import the CSS file

const Usage = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [accountInfo, setAccountInfo] = useState(null);
  const navigate = useNavigate();
  const [usageLimit, setUsageLimit] = useState(0);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const response = await getAccountInfo();
        if (response.result === 'success') {
          console.log('Account Info:', response.accountInfo);
          setAccountInfo(response.accountInfo);
          console.log('Subscription Type:', response.accountInfo.subscription_type);

          switch (response.accountInfo.subscription_type) {
            case 'free':
              setUsageLimit(120);
              break;
            case 'prod_O8wYwjy7Op6eSU':
              setUsageLimit(300);
              break;
            case 'prod_O8wcbG8gumzDs1':
              setUsageLimit(1440);
              break;
            default:
              setUsageLimit(0);
          }
        }
      })();
    }
  }, [isLoggedIn]);

  const handleLogout = async () => {
    const response = await logout();

    if (response.result === 'success') {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      console.error(response.message);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(prevState => !prevState);
  };

  return (
    <div className="outer-usage-container">
      
      <div className="sidebar-container">
      <Sidebar handleLogout={handleLogout} sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle}/>
      </div>
      <div className="usage-container">
      <button className="sidebar-toggle" onClick={() => setSidebarOpen(true)}>Menu</button>
        <h1>Account Info</h1>
        {accountInfo ? (
          <div className="info-box">
            {/* Display the account information */}
            <p>Total Usage: {accountInfo.total_minutes_processed} minutes</p>
            <p>This Month Upload Usage: {accountInfo.monthly_minutes_processed} minutes</p>
            

            {accountInfo && usageLimit > 0 ? (
              <div className="usage-bar-container">
                <div className="usage-bar" style={{ 
                  width: `${(accountInfo.monthly_minutes_processed / usageLimit) * 100}%`, 
                }} />
              </div>
            ) : null}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Usage;
