import React, { useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import BetaSignup from '../components/BetaSignup';
import ContentSection from '../components/ContentSection';
import { betaSignup } from '../services/api';
import shortForm from './featureimages/shortform1.png'
import smrtCrop from './featureimages/smartcropping.png'
import subtitles from './featureimages/subtitles.png'
import transcription from './featureimages/transcript.png'
import translation from './featureimages/translation.png'
import contentSpec from './featureimages/contentspecific.png'
import fullLogo from './featureimages/logonobg.png'
import typing from './featureimages/typing.png'
import clips from './featureimages/clips.png'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import NewsCarousel from '../components/NewsCarousel'; // adjust the path as necessary
import AuthContext from "../AuthContext";
import FeaturesCarousel from '../components/FeaturesCarousel';
import FAQ from '../components/FAQ';
import Pricing from '../components/Pricing';
import Walkthrough from '../components/Walkthrough';  
import Section from '../components/Section';
import { COLORS } from '../constants/Colors';
import { getBackgroundForSection } from '../constants/Colors';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import { gsap } from 'gsap';
import WelcomeSection from '../components/WelcomeSection';
import NewWelcomeSection from '../components/NewWelcomeSection';




import './NewLanding.css';


function NewLanding() {
  return (
    <ParallaxProvider>
    <>
    <Section color={COLORS.WELCOME}>
        <WelcomeSection />
    </Section>

      
      <Section color={COLORS.WALKTHROUGH}>
        <Walkthrough />
      </Section>

      <Section color={COLORS.FEATURES}>
        <FeaturesCarousel />
      </Section>


      <Section color={COLORS.FAQ}>
        <FAQ />
      </Section>

      <Section color={COLORS.PRICING}>
        <Pricing /> 
      </Section>
      <a href="https://discord.com/invite/xzkVWRKvZ3" target="_blank" rel="noopener noreferrer" className="discord-invite-button">
        <i className="fab fa-discord"></i> {/* Assuming you're using Font Awesome for the Discord logo */}
      </a>

    </>
    </ParallaxProvider>
  );
}

export default NewLanding;
