import React, { useState } from 'react';
import { getReferralLink, getAffiliateData } from '../services/api';  // Import the getAffiliateData function
import Sidebar from '../components/Sidebar';
import './Affiliates.css';

const Affiliates = () => {
  const [referralLink, setReferralLink] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [referredUsers, setReferredUsers] = useState(0);  // Add state for the referred users count
  const [earnings, setEarnings] = useState(0);  // Add state for the earnings

  const handleGetReferralLink = async () => {
    const response = await getReferralLink();
    if (response.result === 'success') {
      setReferralLink(response.link);
      const affiliateData = await getAffiliateData();
      if (affiliateData.result === 'success') {
        setReferredUsers(affiliateData.referredUsers);
        setEarnings(affiliateData.earnings);
      }
    } else {
      console.error(response.message);
    }
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(prevState => !prevState);
  };

  return (
    <div className="outer-affiliates-container">
      <div className="sidebar-container">
        <Sidebar sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle} />
      </div>
      
      <div className="affiliates-container">
        <button className="sidebar-toggle" onClick={handleSidebarToggle}>Menu</button>
        <button className = 'affiliate-info-button' onClick={() => setShowInfo(!showInfo)}>Affiliate Program Info</button>
        <div className={`affiliates-info ${showInfo ? 'show' : ''}`}>
        <h1>Editair Affiliate Program</h1>
      <p>The Editair Affiliate Program is an exciting opportunity for content creators, bloggers, influencers, and technology enthusiasts to earn commissions by promoting our advanced video editing platform, Editair.</p>

      <h2>How It Works</h2>
      <p>By clicking the button below, you'll be entering our affiliate program and receive a unique affiliate link. Share this link on your website, blog, social media posts, videos, or any other digital platform where you engage with your audience. When someone clicks on your link and signs up for an Editair subscription, you earn a 30% commission for the first two months of their subscription.</p>

      <h2>Benefits</h2>
      <ul>
        <li>Competitive Commission Rates: We offer a generous commission rate of 30% for each new Editair subscription that comes through your affiliate link for the first two months.</li>
        <li>Access to Promotional Material: We provide you with a range of promotional materials including banners, social media posts, and email templates to make your marketing efforts as easy and effective as possible.</li>
        <li>Real-Time Tracking: Through our affiliate dashboard, you can monitor clicks, conversions, and commissions in real time. These metrics will appear below once you have started to accumulate them.</li>
        <li>Monthly Payouts: Your commissions will be paid out on a monthly basis via PayPal.</li>
      </ul>

      <h2>Who Can Join?</h2>
      <p>Anyone with an audience that could benefit from our platform is welcome to join the Editair Affiliate Program. We'd love to have you onboard!</p>

      <p>Join Editair's Affiliate Program today and start earning! Simply click the button below to reveal your unique affiliate link and start promoting Editair.</p>
      </div>

      <div className="affiliates-action-container">
          <button className="affiliates-button" onClick={handleGetReferralLink}>{referralLink ? 'Share Your Personal Link To Earn!' : 'Reveal Your Referral Link'}</button>
          {referralLink && (
  <>
    <p>{referralLink}</p>
    <p>Number of Referred Users: {referredUsers.length}</p>
    <p>Monthly Earnings:</p>
    <ul>
      {Object.entries(earnings).map(([month, earnings]) => (
        <li key={month}>{month}: {earnings}</li>
      ))}
    </ul>
  </>
)}
        </div>
      </div>
    </div>
  );
};

export default Affiliates;