// MarkdownRenderer.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import './MarkdownStyles.css'; // Stylesheet for your Markdown content

const MarkdownRenderer = ({ markdown }) => (
  <div className="markdown-body">
    <ReactMarkdown>{markdown}</ReactMarkdown>
  </div>
);

export default MarkdownRenderer;
