import React, { useState } from 'react';
import { chat } from '../services/chatbotAPI';
import './ChatBot.css';

function ChatComponent({ videofile, visible }) {
    const [isBotTyping, setIsBotTyping] = useState(false);

    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([
      { role: 'assistant', content: `Hi, I'm Eddie! Let's edit your video!` }
    ]);

    const handleSendClick = async () => {
        // Add the user's message to the state immediately
        setMessages(prevMessages => [
            ...prevMessages,
            { role: 'user', content: `${input}` }
        ]);
    
        // Clear the input field
        setInput('');
    
        // After user's message is added, show the typing indicator
        setIsBotTyping(true);
    
        // Simulate a short delay for the typing indicator
        setTimeout(async () => {
            const response = await chat(input, videofile);
            
            if (response.result === 'success') {
                setMessages(prevMessages => [
                    ...prevMessages,
                    { role: 'assistant', content: response.message }
                ]);
            } else {
                console.error('An error occurred: ' + response.message);
            }
    
            // Hide the typing indicator
            setIsBotTyping(false);
        }, 1500);  // 1.5 seconds typing simulation, adjust as needed
    };
    
    

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents the form from submitting
            // Here, you can also add the logic to send the chat message to the bot
        }
    }

    return (
        <div className={`chat-container ${visible ? '' : 'chat-closed'}`}>
            <div className="chat-messages">
                {messages.map((message, index) => (
                    <p key={index} className={`chat-message ${message.role}`}>{message.content}</p>
                ))}
                {isBotTyping && (
        <div className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
        </div>
    )}
            </div>
            <div className="chat-input-container">
                <input 
                    className="chat-input"
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type your message..."
                />
                <button 
                    type='button' 
                    className="chat-send-button" 
                    onClick={handleSendClick}
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default ChatComponent;


