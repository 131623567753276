import React, { useEffect } from 'react';
import StarfieldAnimation from 'react-starfield-animation';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';

import logo1 from '../pages/featureimages/EditairWelcome1.png';
import logo2 from '../pages/featureimages/EditairWelcome2.png';
import logo3 from '../pages/featureimages/EditairWelcome3.png';
import Typed from 'typed.js';

function WelcomeSection() {
    let lastScrollAmount = 0;

    const handleScroll = (e) => {
        e.preventDefault();

        let scrollAmount = e.deltaY;
        // We will slow down the scroll effect by dampening it
        const dampenedScrollAmount = scrollAmount * 0.3; 
        lastScrollAmount += dampenedScrollAmount;
    
        const normalizedScroll = Math.min(1, Math.max(0, lastScrollAmount / 5000));

        animateLogos(normalizedScroll);

        // No need to block the overflow since we are just dampening the scroll effect
    };

    useEffect(() => {
        const duotoneElement = document.querySelector('.duotone');
        duotoneElement.setAttribute('data-text', duotoneElement.textContent);
        // ... your other useEffect code ...
    }, []);

    
    
    
    

    const animateLogos = (progress) => {
        const localProgress = progress * 2; 
        gsap.to('.logo1', { y: `${-500 * localProgress}px`, opacity: 1 - localProgress, rotation: 30 * localProgress, duration: 0 });
        gsap.to('.logo2', { y: `${500 * localProgress}px`, opacity: 1 - localProgress, rotation: -30 * localProgress, duration: 0 });
        gsap.to('.logo3', { x: `${-500 * localProgress}px`, opacity: 1 - localProgress, duration: 0 });
        gsap.to('.logo1', { scale: 1 - (0.5 * localProgress), duration: 0 });
        gsap.to('.logo2', { scale: 1 - (0.5 * localProgress), duration: 0 });
        gsap.to('.logo3', { scale: 1 - (0.5 * localProgress), duration: 0 });
    };

    useEffect(() => {
        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);
    

    
    return (
        <>
            <div id="welcome-section-begin"></div>
            <div className="main-container">
                <StarfieldAnimation 
                    style={{
                    position: 'absolute',
                    width: '100%',
                    height: '70%',
                    zIndex: 0
                    }}
                    numStars={50}
                    depth={900}
                />
                {/* <div className="logo-container">
                    <img src={logo1} alt="Logo 1" className="stacked-logo logo1" />
                    <img src={logo2} alt="Logo 2" className="stacked-logo logo2" />
                    <img src={logo3} alt="Logo 3" className="stacked-logo logo3" />
                </div> */}
                <div className="text-container">
                    <h1 className="air-text">
                        <span className="duotone"> There's a New Way to Viral Success. </span>
                        Fully Automated.</h1>
                    <h1 className="ai-powered-text">
                    From crafting perfect short-form clips to automated social posting — we’ve got it all covered. Try EditAir today.
                    </h1>
                    <Link to="/signup" className="btn-signup-link">
                        <button className="btn-signup">Get Started For Free</button>
                    </Link>
                </div>
                

            </div>
            <div id="welcome-section-end"></div>

        </>
    );
    
}

export default WelcomeSection;
