import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'; // Don't forget to import your CSS file.


const Sidebar = ({ handleLogout, sidebarOpen, handleSidebarToggle}) => {
  return (
    <div className={`profile-sidebar ${sidebarOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={handleSidebarToggle}>Close</button>
      <Link className="sidebar-link" to="/user-profile">Manage Profile</Link>
      <Link className="sidebar-link" to="/subscription">Subscription</Link>
      <Link className="sidebar-link" to="/usage">Usage</Link>
      <Link className="sidebar-link" to="/connected-accounts">Connected Accounts</Link>
      <Link className="sidebar-link" to="/affiliates">Editair Affiliates</Link>
      <button className="sidebar-link sidebar-logout" onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Sidebar;
