import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import { logout } from '../services/auth';
import Sidebar from '../components/Sidebar';
import StripeContainer from '../components/StripeContainer';
import { getSubscriptionStatus } from '../services/api';
import './Subscription.css'; // Import the CSS file

const Subscription = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(prevState => !prevState);
  };

  const subscriptionTypes = {
    'free': { name: 'Free', minutes: 60 },
    'prod_O8wYwjy7Op6eSU': { name: 'Editair Premium', minutes: 240 },
    'prod_O8wcbG8gumzDs1': { name: 'Editair Unlimited', minutes: 1000 },
  };

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const subscriptionStatus = await getSubscriptionStatus();
        if (subscriptionStatus.result === 'success') {
          console.log('Subscription status:', subscriptionStatus.subscription);
          setSubscription(subscriptionStatus.subscription);
        }
      })();
    }
  }, [isLoggedIn]);

  const handleLogout = async () => {
    const response = await logout();

    if (response.result === 'success') {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      console.error(response.message);
    }
  };

  const handleUpdateSubscription = () => {
    // Redirect to the subscription update page
    window.location.href = 'https://billing.stripe.com/p/login/6oEcPC5TScMvdnWcMM';
  };

  return (
    <div className="outer-subscription-container">

      
      <div className="subscription-container">
      <button className="sidebar-toggle" onClick={() => setSidebarOpen(true)}>Menu</button>
      {subscription && subscription !== 'free' && subscription !== 'Free' ? (
  <div className="subscription-info">
    <p className="subscription-type">
      You are subscribed to: {subscriptionTypes[subscription].name}
    </p>
    <p>
      Monthly Usage Limit: {subscriptionTypes[subscription].minutes} minutes
    </p>
    <button onClick={handleUpdateSubscription} className="update-button">
      Update Subscription
    </button>
  </div>
) : (
  <StripeContainer />
)}

      </div>
    </div>
  );
};

export default Subscription;
