import axios from 'axios';
import { getCookie } from '../services/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;


const getSessionIdFromCookie = () => {
  const cookies = document.cookie.split(';');
  console.log("Cookies:", cookies)
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('session=')) {
      return cookie.substring('session='.length);
    }
  }
  return null;
};

const getSessionHeader = (sessionId) => {
  return {
    'session': sessionId,
  };
};

const getSessionIdFromLocalStorage = () => {
  return localStorage.getItem('session');
};


const betaSignup = async (name, email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/beta-signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
      }),
    });

    if (!response.ok) {
      console.error(`API call failed with status: ${response.status}`);
      console.error(await response.text());
      throw new Error('API call failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};





const uploadVideo = async (file, sessionId, setUploadProgress) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(`${API_BASE_URL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'session': sessionId,
      },
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // Introducing damping factor to slow down the progress update
        const dampedProgress = percentCompleted * 0.8;
        setUploadProgress(dampedProgress);
      },
    });

    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while uploading the video.' };
  }
};


const uploadYouTubeLink = async (youtubeLink, sessionId, setUploadProgress) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    
    let progress = 0;
    const intervalId = setInterval(() => {
      progress += 5; // Slower increment
      setUploadProgress(progress);
      if (progress >= 80) { // Stop at 80%
        clearInterval(intervalId);
      }
    }, 500);

    const response = await axios.post(`${API_BASE_URL}/api/upload_youtube`, {
      youtubeLink,
    }, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    // Reset progress
    setUploadProgress(0);

    return response.data;
  } catch (error) {
    // Reset progress
    setUploadProgress(0);
    return { result: 'error', message: 'An error occurred while uploading the YouTube link.' };
  }
};

const getLastUpdated = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(`${API_BASE_URL}/api/last-updated`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error getting last updated timestamp:', error);
    return { result: 'error', message: 'An error occurred while getting last updated timestamp.' };
  }
};

const getUploads = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    console.log("Session ID:", sessionId)
    const response = await axios.get(`${API_BASE_URL}/api/uploads`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    return {
      result: 'success',
      uploads: response.data.uploads,
    };
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the uploads.' };
  }
};

const generateClips = async (videofile, settings) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    
    // Check if color_palette is null and set a default value if necessary
    if (settings.color_palette === null) {
      settings.color_palette = '[(244, 67, 54)]';
    }

    const response = await axios.post(
      `${API_BASE_URL}/api/generate_clips`,
      {
        videofile,
        original_lang: settings.originalLanguage,
        target_lang: settings.targetLanguage,
        text_mode: settings.textStyle,
        text_position: settings.textPosition,
        color_palette: settings.color_palette,
        highlight_color: settings.highlight_color,
        size: settings.textSize,
        font: settings.font,
        crop: settings.crop,
        quality: settings.quality
      },
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while generating the clips.' };
  }
};


const checkClipStatus = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    if (!sessionId) throw new Error("No session found");

    const response = await axios.get(`${API_BASE_URL}/api/clips_status/${videofile}`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    if (response.data.result === "success") {
      return response.data;
    } else {
      throw new Error("Failed to check clip status");
    }
  } catch (error) {
    console.error("Failed to check clip status", error);
    throw error;
  }
};

const getVideoURL = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(
      `${API_BASE_URL}/api/get_video_url/${videofile}`,
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the video URL.' };
  }
};


const getClips = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(
      `${API_BASE_URL}/api/get_clips/${videofile}`,
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the clips.' };
  }
};

const getChapters = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(
      `${API_BASE_URL}/api/get_chapters/${videofile}`,
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the chapters.' };
  }
};

const searchChapters = async (videofile, query) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.post(
      `${API_BASE_URL}/api/search_chapters/${videofile}`,
      { query: query },
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while searching the chapters.' };
  }
};


const getURL = async (filename) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(
      `${API_BASE_URL}/api/get_url/${filename}`,
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the URL.' };
  }
};

const downloadClip = async (videoUrl, startTime, endTime) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(
      `${API_BASE_URL}/api/download_clip`,
      {
        params: {
          'video_url': videoUrl,
          'start_time': parseFloat(startTime),
          'end_time': parseFloat(endTime)
        },
        headers: {
          'Content-Type': 'application/json',
          'session': sessionId,
        },
        responseType: 'blob', // This is crucial to handle binary data correctly
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'clip.mp4'); // Whatever you want the filename to be
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the clip.' };
  }
};





const testClipStatus = async (sessionId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/test_clips_status`, {
      headers: {
        'session': sessionId,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error checking test clip status:', error);
    throw error;
  }
};

const getSubscriptionStatus = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(`${API_BASE_URL}/api/get_subscription`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error getting subscription status:', error);
    return { result: 'error', message: 'An error occurred while getting subscription status.' };
  }
};

const getAccountInfo = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    console.log("Session ID:", sessionId)
    const response = await axios.get(`${API_BASE_URL}/api/account_info`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    return {
      result: 'success',
      accountInfo: response.data.account_info,
    };
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the account info.' };
  }
};


const getProcessingStatus = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    if (!sessionId) throw new Error("No session found");

    const response = await axios.get(`${API_BASE_URL}/api/processing_status/${videofile}`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    if (response.data.result === "success") {
      const newData = response.data;

      // Initialize an empty object to hold the converted data
      let convertedData = {};

      for (let key in newData) {
        if (newData.hasOwnProperty(key)) {
          // Check for 'status' to identify new format
          if (newData[key] && typeof newData[key] === 'object' && 'status' in newData[key]) {
            convertedData[key] = newData[key].status;
          } else {
            // Old format
            convertedData[key] = newData[key];
          }
        }
      }

      return convertedData;
    } else {
      throw new Error("Failed to check clip status");
    }
  } catch (error) {
    console.error("Failed to check clip status", error);
    throw error;
  }
};

const getReferralLink = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    console.log("Session ID:", sessionId)
    const response = await axios.get(`${API_BASE_URL}/api/get_referral_link`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    return {
      result: 'success',
      link: response.data.link, // Assuming the referral link is returned under 'link'
    };
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the referral link.' };
  }
};

const getAffiliateData = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    console.log("Session ID:", sessionId)
    const response = await axios.get(`${API_BASE_URL}/api/get_affiliate_data`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    return {
      result: 'success',
      referredUsers: response.data.referred_users, // Assuming the referred users data is returned under 'referred_users'
      earnings: response.data.earnings // Assuming the earnings data is returned under 'earnings'
    };
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the affiliate data.' };
  }
};

const getEditedVideos = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    if (!sessionId) throw new Error("No session found");

    const response = await axios.get(`${API_BASE_URL}/api/get_edited_videos/${videofile}`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    if (response.data.result === "success") {
      return response.data.edited_videos;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("Failed to get edited videos", error);
    throw error;
  }
};

const checkTikTokRegistration = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    console.log("Session ID:", sessionId);
    const response = await axios.get(`${API_BASE_URL}/api/has_tiktok_token`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    return {
      result: 'success',
      tokenBool: response.data.token_bool // Assuming the token boolean is returned under 'token_bool'
    };
  } catch (error) {
    return { result: 'error', message: 'An error occurred while checking the TikTok registration.' };
  }
};


const checkYoutubeRegistration = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await fetch(`${API_BASE_URL}/api/has_youtube_token`, {
      method: 'GET',
      headers: {
        'session': sessionId
      }
    });

    const data = await response.json();

    if (data.result === 'success' && data.token_bool) {
      console.warn('Token is present', data);
      return {
        result: "success",
        tokenBool: data.token_bool, // Assuming the token boolean is returned under 'token_bool'
      };
    } else {
      console.log('Token is not present');
    }
  } catch (error) {
    console.error('Error:', error);
    return {
      result: "error",
      message: "An error occurred while checking the Youtube registration.",
    };
  }
};

const savePreset = async (presetName, presetSettings) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.post(
      `${API_BASE_URL}/api/new_preset`,
      {
        preset_name: presetName,
        preset_settings: presetSettings
      },
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while saving the preset.' };
  }
};

const getPresets = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.get(
      `${API_BASE_URL}/api/get_presets`,
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while fetching the presets.' };
  }
};

const deletePreset = async (presetName) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.post(
      `${API_BASE_URL}/api/delete_preset`,
      {
        preset_name: presetName,
      },
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while deleting the preset.' };
  }
};

const getPreviewUrls = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    if (!sessionId) throw new Error("No session found");

    const response = await axios.get(`${API_BASE_URL}/api/get_preview_urls/${videofile}`, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });

    if (response.data.result === "success") {
      return response.data.preview_urls;
    } else {
      throw new Error("Failed to get preview URLs");
    }
  } catch (error) {
    console.error("Failed to get preview URLs", error);
    throw error;
  }
};


const trackTikTokUpload = async (videofile, start_time, stop_time) => {
  try {
    const sessionId = getSessionIdFromLocalStorage(); // Assuming you have this function to get the session ID.
    const response = await axios.post(`${API_BASE_URL}/api/track-tiktok-upload`, {
      videofile,
      start_time,
      stop_time
    }, {
      headers: {
        'session': sessionId,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error tracking TikTok upload:', error);
    return { result: 'error', message: 'An error occurred while tracking the TikTok upload.' };
  }
};

const saveSurveyResponses = async (surveyResponses) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();
    const response = await axios.post(
      `${API_BASE_URL}/api/save_survey`,
      {
        survey_responses: surveyResponses
      },
      {
        headers: {
          'session': sessionId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: 'error', message: 'An error occurred while saving the survey responses.' };
  }
};






export {
  betaSignup,
  uploadVideo,
  uploadYouTubeLink,
  getUploads,
  getURL,
  getVideoURL,
  getLastUpdated,
  generateClips,
  getClips,
  getChapters,
  searchChapters,
  checkClipStatus,
  downloadClip,
  testClipStatus,
  getSubscriptionStatus,
  getAccountInfo,
  getProcessingStatus,
  getReferralLink,
  getAffiliateData,
  getEditedVideos,
  checkTikTokRegistration,
  checkYoutubeRegistration,
  savePreset,
  getPresets,
  deletePreset,
  getPreviewUrls,
  trackTikTokUpload,
  saveSurveyResponses


};