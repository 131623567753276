import React, { useState, useEffect, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';

function ColorPicker(props) {
    const [showPicker, setShowPicker] = useState(false);
    const pickerRef = useRef(null);

    const colors = ['#0D638F', '#9c27b0', '#e91e63'];

    useEffect(() => {
        // Set the default color as the first preset color when initialized
        props.setColor(colors[0]);
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef]);

    const handlePresetColor = (color) => {
        props.setColor(color);
        setShowPicker(false);
    };

    const buttonStyle = {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        margin: '5px',
        border: 'none',
        cursor: 'pointer'
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap'
    };

    const isCustomColor = !colors.includes(props.color);
    const plusButtonStyle = {
        ...buttonStyle,
        background: isCustomColor ? props.color : `conic-gradient(
            red 0% 12.5%, 
            orange 12.5% 25%, 
            yellow 25% 37.5%, 
            green 37.5% 50%, 
            blue 50% 62.5%, 
            indigo 62.5% 75%, 
            violet 75% 87.5%, 
            red 87.5% 100%
        )`,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        border: isCustomColor ? '2px solid black' : 'none'
    };

    return (
        <div style={containerStyle} ref={pickerRef}>
            {colors.map((color, index) => (
                <button 
                    type='button'
                    key={index}
                    style={
                      props.color === color 
                      ? {...buttonStyle, backgroundColor: color, border: '2px solid black'} 
                      : {...buttonStyle, backgroundColor: color}
                    } 
                    onClick={() => handlePresetColor(color)}
                ></button>
            ))}
            <button type='button' style={plusButtonStyle} onClick={() => setShowPicker(!showPicker)}>
                {colors.includes(props.color) ? '+' : '✓'}
            </button>
            {showPicker && <HexColorPicker color={props.color} onChange={props.setColor} />}
        </div>
    );
}

export default ColorPicker;
