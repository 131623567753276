// BlogPost.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MarkdownRenderer from './MarkdownRenderer';
import './BlogPost.css'

const BlogPost = () => {
  const { postId } = useParams();
  const [postContent, setPostContent] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    // Dynamic import to fetch the actual content of the Markdown file
    import(`../posts/${postId}.md`)
      .then(res => {
        // Fetch the raw text of the file
        fetch(res.default)
          .then(response => response.text())
          .then(text => setPostContent(text))
          .catch(err => {
            console.error('Failed to load post content', err);
            setError('Could not load post content.');
          });
      })
      .catch(err => {
        console.error('Failed to load post file', err);
        setError('Post not found');
      });
  }, [postId]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!postContent) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-page-container">
    <div className="blog-post-container">
      <MarkdownRenderer markdown={postContent} />
    </div>
    </div>
  );
};

export default BlogPost;
