// ContactUs.js
import React from 'react';
import emailjs from 'emailjs-com';
import './ContactUs.css';

// Add this function inside your ContactUs component
const sendEmail = (e) => {
    e.preventDefault();
  
    // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with your own values from EmailJS
    emailjs.sendForm('service_lxyl9xn', 'template_moa21s6', e.target, 'fMdL6SUUAxUHjYSjd')
      .then((result) => {
        console.log(result.text);
        alert('Your message has been sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send your message. Please try again.');
      });
  
    // Reset the form fields
    e.target.reset();
  };

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <p>Please fill out the form below, and we'll get back to you as soon as possible.</p>
      <form className="contact-form" onSubmit={sendEmail}>
        <label htmlFor="name">Name</label>
        <input type="text" name="name" placeholder="Your Name" required />

        <label htmlFor="email">Email</label>
        <input type="email" name="email" placeholder="Your Email" required />

        <label htmlFor="message">Message</label>
        <textarea name="message" placeholder="Your message" required></textarea>

        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default ContactUs;
