import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC4of9VG3wnw-DN-U1nNNQj-KQFiiOpOlg",
  authDomain: "authenticated-webapp.firebaseapp.com",
  projectId: "authenticated-webapp",
  storageBucket: "authenticated-webapp.appspot.com",
  messagingSenderId: "1049773570396",
  appId: "1:1049773570396:web:ba5c7f147ea9b2fa262999"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
console.log("Firestore instance:", db);


export { db };
