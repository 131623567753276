import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../AuthContext";
import { logout, tiktokLogout, youtubeLogout } from "../services/auth";
import { tiktokLogin, youtubeLogin } from "../services/auth";
// import { checkTikTokRegistration } from "../services/api";
import Sidebar from "../components/Sidebar";
import "./ConnectedAccounts.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ConnectedAccounts = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [tikTokAvatarUrl, setTikTokAvatarUrl] = useState("");
  const [tikTokUsername, setTikTokUsername] = useState("");
  const [tikTokNickname, setTikTokNickname] = useState("");
  const [isTikTokConnected, setIsTikTokConnected] = useState(false);
  const [isTikTokLoading, setIsTikTokLoading] = useState(true);

  // set same properties for youtube
  const [youtubeAvatarUrl, setYoutubeAvatarUrl] = useState("");
  const [youtubeUsername, setYoutubeUsername] = useState("");
  const [youtubeCustomUrl, setYoutubeCustomUrl] = useState("");
  const [isYoutubeConnected, setIsYoutubeConnected] = useState(false);
  const [isYoutubeLoading, setIsYoutubeLoading] = useState(true);

  const handleLogout = async () => {
    const response = await logout();

    if (response.result === "success") {
      setIsLoggedIn(false);
      navigate("/");
    } else {
      console.error(response.message);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const handleTikTokLogin = async () => {
    const response = await tiktokLogin();

    if (response.result === "success") {
      // Handle a successful TikTok login (e.g., update the app state)
      window.location.href = response.auth_url;
    } else {
      console.error(response, "Error while connecting to tiktok");
    }
  };

  const handleTikTokLogout = async () => {
    const response = await tiktokLogout()
    if (response.result === "success") {
      console.log("Successfully logged out of youtube");
    } else {
      console.error(response, "Error while logging out of youtube");
    }
    setIsTikTokConnected(false);
  }

  const handleYoutubeLogin = async () => {
    const response = await youtubeLogin();

    if (response.result === "success") {
      // Handle a successful TikTok login (e.g., update the app state)
      window.location.href = response.auth_url;
    } else {
      console.error(response, "Error while connecting to tiktok");
    }
  };

  const handleYoutubeLogout = async () => {
    const response = await youtubeLogout();
    if (response.result === "success") {
      console.log("Successfully logged out of youtube");
    } else {
      console.error(response, "Error while logging out of youtube");
    }
    setIsYoutubeConnected(false);
  }

  useEffect(() => {
    const fetchTikTokStatusAndInfo = async () => {
      setIsTikTokLoading(true);

      const session = localStorage.getItem("session");
      const config = {
        headers: {
          session: session,
        },
      };
      axios
        .get(`${API_BASE_URL}/api/tiktok-creator-info`, config)
        .then((response) => {
          setTikTokAvatarUrl(response.data.creator_avatar_url);
          setTikTokUsername(response.data.creator_username);
          setTikTokNickname(response.data.creator_nickname);
          setIsTikTokConnected(true);
          // ... other settings if necessary
          setIsTikTokLoading(false);
        })
        .catch((error) => {
          // Handle error case
          setIsTikTokLoading(false);
          if (error.response && error.response.data) {
            console.error("Error:", error.response.data.message);
          } else {
            console.error("An error occurred:", error.message);
          }
        });
    };

    fetchTikTokStatusAndInfo();
  }, [isLoggedIn]); // Re-fetch when the login status changes

  useEffect(() => {
    const fetchYoutubeStatusAndInfo = async () => {
      setIsYoutubeLoading(true);

      const session = localStorage.getItem("session");
      const config = {
        headers: {
          session: session,
        },
      };
      axios
        .get(`${API_BASE_URL}/api/youtube-creator-info`, config)
        .then((response) => {
          setYoutubeAvatarUrl(response.data.data.thumbnails.default.url);
          setYoutubeUsername(response.data.data.title);
          setYoutubeCustomUrl(response.data.data.customUrl);
          setIsYoutubeConnected(true);
          setIsYoutubeLoading(false);
        })
        .catch((error) => {
          // Handle error case
          setIsYoutubeLoading(false);
          if (error.response && error.response.data) {
            console.error("Error:", error.response.data.message);
          } else {
            console.error("An error occurred:", error.message);
          }
        });
    };

    fetchYoutubeStatusAndInfo();
  }, [isLoggedIn]); // Re-fetch when the login status changes

  return (
    <div className="profile-container">
      

      <div className="con-container">
        <h1>Connected Accounts</h1>
        {/* Your profile content here */}
        <>
          {isTikTokLoading ? (
            <div className="loading-container">
              <div className="spinner-tok"></div>
            </div>
          ) : isTikTokConnected ? (
            <div>
              <h2 className="tiktok-title">Your Connected TikTok Account:</h2>
              <div className="tiktok-connected-info">
                <img src={tikTokAvatarUrl} alt="TikTok Avatar" />
                <div>
                  <p>Username: {tikTokUsername}</p>
                  <p>Nickname: {tikTokNickname}</p>
                </div>
              </div>
              <button className="nav-link nav-link-tiktok" onClick={() => handleTikTokLogout()}>
                Disconnect Tiktok
              </button>
            </div>
          ) : (
            <button
              className="nav-link nav-link-tiktok"
              onClick={() => handleTikTokLogin()}
            >
              Continue with TikTok
            </button>
          )}
        </>
        <>
          {isYoutubeLoading ? (
            <div className="loading-container">
              <div className="spinner-tok"></div>
            </div>
          ) : isYoutubeConnected ? (
            <div>
              <h2 className="tiktok-title">Your Connected Youtube Account:</h2>
              <div className="tiktok-connected-info">
                <img src={youtubeAvatarUrl} alt="TikTok Avatar" />
                <div>
                  <p>Username: {youtubeUsername}</p>
                  <p>CustomUrl: {youtubeCustomUrl}</p>
                </div>
              </div>
              <button className="nav-link nav-link-tiktok" onClick={() => handleYoutubeLogout()}>
                Disconnect Youtube
              </button>
            </div>
          ) : (
            <button
              className="nav-link nav-link-tiktok"
              onClick={() => handleYoutubeLogin()}
            >
              Continue with Youtube
            </button>
          )}
        </>
      </div>
    </div>
  );
};

export default ConnectedAccounts;
