import React from 'react';
import { Link } from 'react-router-dom';
import './Pricing.css';

const Pricing = () => {
  return (
    <div className="pricing-section" id="pricing">
      <h2>A Pricing Plan for Everyone</h2>
      <p className="pricing-tagline">
        Resource allocation is geared towards community-driven improvements. Your input is vital—
        <a href="https://discord.com/invite/xzkVWRKvZ3" target="_blank" rel="noopener noreferrer">Join our community here</a>.
      </p>
      <div className="pricing-grid">
        <Link to="/signup">
          <div className="pricing-plan">
            <div className="pricing-plan-header">
              <h3>Free</h3>
              <p className="pricing-plan-price">Try EditAir Out - no cost</p>
            </div>
            <div className="pricing-plan-features">
              <ul>
                <li>120 Minutes Total</li>
                <li>Custom Styled Subtitles</li>
                <li>Saved Presets</li>
                <li>Multilingual Support</li>
                <li>IntellectSearch</li>
                <li>HD Download</li>
                <li>AI Social Media Captions</li>
                <li>Export with Watermark</li>
              </ul>
            </div>
          </div>
        </Link>
        <Link to="/signup">
          <div className="pricing-plan">
            <div className="pricing-plan-header">
              <h3>Premium</h3>
              <p className="pricing-plan-price">$20/mo or $200 annually</p>
            </div>
            <div className="pricing-plan-features">
              <ul>
                <li>300 minutes per month</li>
                <li>Unlimited Upload</li>
                <li>Custom Styled Subtitles</li>
                <li>Saved Presets</li>
                <li>Multilingual Support</li>
                <li>Translated Subtitles</li>
                <li>IntellectSearch</li>
                <li>HD Download</li>
                <li>AI Social Media Captions</li>
                <li>10 Daily Uploads to TikTok and YouTube</li>
              </ul>
            </div>
          </div>
        </Link>
        <Link to="/signup">
          <div className="pricing-plan">
            <div className="pricing-plan-header">
              <h3>Creator</h3>
              <p className="pricing-plan-price">$80/mo or $800 annually</p>
            </div>
            <div className="pricing-plan-features">
              <ul>
                <li>20 hours per month</li>
                <li>Unlimited Upload</li>
                <li>Custom Styled Subtitles</li>
                <li>Saved Presets</li>
                <li>Multilingual Support</li>
                <li>Translated Subtitles</li>
                <li>IntellectSearch</li>
                <li>HD Download</li>
                <li>AI Social Media Captions</li>
                <li>Unlimited Posts to Social Media</li>
                <li>Auto Post to TikTok & Youtube</li>
                <li>Access to Beta Features</li>
              </ul>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Pricing;
