import React, { useState, useEffect } from "react";
import axios from "axios";
import './TiktokUpload.css';
import Confetti from 'react-confetti';

// Creator info data needs to be rendered in UI
// "creator_avatar_url": "https://lf16-tt4d.tiktokcdn.com/obj/tiktok-open-platform/8d5740ac3844be417beeacd0df75aef1",
// "creator_username": "tiktok",
// "creator_nickname": "TikTok Official",
// "privacy_level_options": ["PUBLIC_TO_EVERYONE", "MUTUAL_FOLLOW_FRIENDS", "SELF_ONLY"]
// "comment_disabled": false,
// "duet_disabled": false,
// "stitch_disabled": true,
// "max_video_post_duration_sec": 300

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const YoutubeUpload = (props) => {
  // Creator info needed for rending
  const [categories, setCategories] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showTikTokConfetti, setShowTikTokConfetti] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(true);
  // Set it to true initially since the content will be loading when the component mounts.

  const [avatarUrl, setAvatarUrl] = useState("");
  const [username, setUsername] = useState("");
  const [customUrl, setCustomUrl] = useState("");

  // Trimmed Clip information
  const videourl = props.videourl;
  const start_time = props.start;
  const stop_time = props.stop;
  const title = props.title;
  const description = props.description

  // form data stored in these state variables
  const [categoryID, setCategoryID] = useState(22);

  // axios.post(/tiktok-upload, (videoUrl, trim[0], trim[1]))

  useEffect(() => {
    const session = localStorage.getItem("session");
    const config = {
      headers: {
        session: session,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/youtube-creator-info?onlyCategory=true`, config)
      .then((response) => {
        setCategories(response.data.data)
      })
      .catch((error) => {
        console.error("Error fetching Youtube creator info:", error);
        // Handle error accordingly, maybe even update the state to reflect an error state.
        setIsAccountLoading(false);  // Still set to false to stop the spinner, but handle the error appropriately.
      });

    axios.get(`${API_BASE_URL}/api/youtube-creator-info`, config)
      .then((response) => {
        setAvatarUrl(response.data.data.thumbnails.default.url);
        setUsername(response.data.data.title);
        setCustomUrl(response.data.data.customUrl);
        setIsAccountLoading(false);
      }
    )
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Prepare the data to send to the backend
    const formData = {
      videourl,
      start_time,
      stop_time,
      title,
      description,
      categoryID
    };

    const session = localStorage.getItem("session");

    fetch(`${API_BASE_URL}/api/youtube-upload`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        session: session,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setShowTikTokConfetti(true);
        setTimeout(() => setShowTikTokConfetti(false), 5000); // hide confetti after 5 seconds
        // Handle the response from the backend if needed
        console.log(data);
      })
      .catch((error) => {
        setIsLoading(false);
        // Handle errors if needed
        console.error(error);
      });
  };

  if (categories === "") {
    return <div></div>
  }
  
  // check if category is null
  if (categories === null) {
    return <div></div>
  }

  if (categories === undefined) {
    return <div></div>
  }

  // check if category is empty
  if (categories.length === 0) {
    return <div></div>
  }

  // check if category is empty
  if (categories.length === undefined) {
    return <div></div>
  }

  return (
    <>
    {isAccountLoading ? (
      <div className="loading-container">
        <div className="spinner-tok"></div>
        <p>Loading...</p>
      </div>
    ) : (
      <>
        {showTikTokConfetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            colors={["#420c68", "#800080", "#c19cdb", "#000000"]}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div className="tiktok">
          <img src={avatarUrl} alt="avatar" />
            <div>
              <p>{username}</p>
              <h4>{customUrl}</h4>
            </div>        
          </div>
          <div>
            <section>
            <label>Category:</label>
            <select value={categoryID} onChange={(e) => setCategoryID(e.target.value)}>
              {categories.map((category) => (
                <option key={category['snippet']['title']} value={category['id']}>
                  {category.snippet.title} {/* Fallback to privacyOption if not in mapping */}
                </option>
              ))}
            </select>
            </section>
          </div>
          <button className='tiktokbtn' type="submit" disabled={isLoading}>
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Posting...
              </>
            ) : (
              "Post"
            )}
          </button>
        </form>
      </>
    )}
    </>
  );
  
  
};

export default YoutubeUpload;
