// src/components/PrivacyPolicy.js
import React from 'react';
import privacyPolicyHTML from '../PrivacyPolicyHTML';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy">
        <h1>Privacy Policy</h1>
        <div
          className="privacy-policy-content"
          dangerouslySetInnerHTML={{ __html: privacyPolicyHTML }}
        />
      </div>
    </>
  );
};

export default PrivacyPolicy;
