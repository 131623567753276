import React from 'react';

const BottomTextPosition = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="100" viewBox="0 0 50 100">
    <rect width="50" height="100" rx="10" style={{fill:"lightgrey", strokeWidth:3, stroke:"rgb(0,0,0)"}} />
    <rect y="80" width="50" height="10" style={{fill:"purple", strokeWidth:3, stroke:"rgb(0,0,0)"}} />
  </svg>
);

export default BottomTextPosition;
