import React from 'react';

function Section({ color, children, ...rest }) {
  const sectionStyle = {
    backgroundImage: color,
    transition: '0.3s ease',
    minHeight: '50vh',   // Ensures section takes up full screen height
    display: 'flex',      // These two lines center the content vertically
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',  // This centers the content horizontally
    padding: '2rem',       // Some padding to prevent content being too close to the edges
    overflow: 'visible'
  };

  return (
    <div style={sectionStyle} {...rest}>
      {children}
    </div>
  );
}

export default Section;
