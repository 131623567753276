import React, { useState, useEffect, useRef } from 'react';
import './Walkthrough.css';
import { gsap } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import ScrollMagic from 'scrollmagic';


const Walkthrough = () => {
  const [activeTab, setActiveTab] = useState('1. Upload Video');

  const videoUrls = {
    '1. Upload Video': 'https://storage.googleapis.com/authenticated-webapp.appspot.com/admin%2Bdemovideo%40editair.app/51e1ceebe2c5f86a95ca8207aa40f33e0b0b25ac6128bb35ffad9cd248ec2f90/51e1ceebe2c5f86a95ca8207aa40f33e0b0b25ac6128bb35ffad9cd248ec2f90.mp4',
    '2. Choose your Settings': 'https://storage.googleapis.com/authenticated-webapp.appspot.com/admin%2Bdemovideo%40editair.app/1c74c36399834e0ab4050b7af32519cd80264eaaca612c8b9fb8d10036ec829f/1c74c36399834e0ab4050b7af32519cd80264eaaca612c8b9fb8d10036ec829f.mp4',
    '3. View Clips': 'https://storage.googleapis.com/authenticated-webapp.appspot.com/admin%2Bdemovideo%40editair.app/91fcbd32a028da9e5cd0a7a8f8eb75b1620e1246c9815c5f4582b3a7ebf11fb8/91fcbd32a028da9e5cd0a7a8f8eb75b1620e1246c9815c5f4582b3a7ebf11fb8.mp4',
    '4. Post': 'https://storage.googleapis.com/authenticated-webapp.appspot.com/admin%2Bdemovideo%40editair.app/2fccd2dae77382fa5c7706dfdaa13c510bf379f029f14b8ccf6bb963b27b29ab/2fccd2dae77382fa5c7706dfdaa13c510bf379f029f14b8ccf6bb963b27b29ab.mp4'
  };

  const tabDescriptions = {
    '1. Upload Video': 'Effortlessly upload your long-form content and let EditAir’s advanced AI algorithms do the heavy lifting.',
    '2. Choose your Settings': 'Tailor your experience with intuitive editing options. From text styles to Smart Crop modes, you’re in control.',
    '3. View Clips': 'Explore AI-curated clips, optimized for engagement and relevance. EditAir saves you hours by doing the sorting for you.',
    '4. Post': 'Seamlessly push your polished, bite-sized clips to your favorite social platforms. Optimize your reach without leaving EditAir.'
  };

  ScrollMagicPluginGsap(ScrollMagic, gsap);


  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
  
    if (!isMobile) {
      const controller = new ScrollMagic.Controller();
      const tl = gsap.timeline();
  
      tl.fromTo(".left-container", { scale: 1.3 }, { x: "0%", scale: 1, duration: 1 })
        .fromTo(".right-container", { x: "100%", opacity: 0 }, { x: "0%", opacity: 1, duration: 1 }, "-=1"); // modified this line
  
      new ScrollMagic.Scene({
        triggerElement: "#logo-container",
        duration: "60%",
        triggerHook: 0.5,
      })
        .setTween(tl)
        .addTo(controller);
  
      return () => {
        controller.destroy();
      };
    } else {
      const rightContainer = document.querySelector(".right-container");
      if (rightContainer) {
        gsap.to(rightContainer, { x: "0%", opacity: 1, duration: 0 }); // immediately set to visible
      }
    }
  }, []);
  

  useEffect(() => {

    const isMobile = window.innerWidth <= 768;
    if (!isMobile) {
      const controller = new ScrollMagic.Controller();
      const tl = gsap.timeline();


    const handleScroll = () => {
      const logoOverlay = document.querySelector('.logo-overlay');
      if (logoOverlay) {
        let opacity = 1 - (window.scrollY - 275) / 100; // Updated calculation here
        opacity = Math.min(Math.max(opacity, 0), 1); // Ensure opacity stays between 0 and 1
        logoOverlay.style.opacity = opacity.toString();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }
  }, []);



  const videoRef = useRef(null); // Single video reference for the currently active video

  const playVideo = () => {
    const playPromise = videoRef.current.play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          // Automatic playback started!
        })
        .catch(error => {
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        });
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window;

      if (scrollY > 325) {
        playVideo();
      } else {
        pauseVideo();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="walkthrough-container" id="features">
      <div className="left-container">
      { isMobile ? 
      // Mobile view without SVG
      <div className="mobile-computer-window">
        <div className="video-container">
          <div className="logo-overlay"></div>
          <video ref={videoRef} key={activeTab} style={{ objectFit: 'fill', width: '100%', height: '100%' }} muted loop playsInline src={videoUrls[activeTab]}></video>
        </div>
      </div>
      :
        <svg width="600" height="400" className="computer-window">
          <rect x="0" y="0" rx="5" ry="5" width="600" height="360" style={{ fill: "rgb(224, 102, 210)" }} />
          <circle cx="20" cy="20" r="5" style={{ fill: "#d9cff1" }} />
          <circle cx="40" cy="20" r="5" style={{ fill: "#d9cff1" }} />
          <circle cx="60" cy="20" r="5" style={{ fill: "#d9cff1" }} />
          <foreignObject x="0" y="40" width="600" height="320" className="video-foreign-object">
            <div className="landing-video-container">
              <div className="logo-overlay"></div>
              <video ref={videoRef} key={activeTab} style={{ objectFit: 'fill', width: '100%', height: '100%' }} muted loop playsInline src={videoUrls[activeTab]}>
              </video>
            </div>
          </foreignObject>

        </svg>
        }
      </div>
      <div className="right-container">
        <div className="section-title">
          <span className="highlight">A New Era</span> of Content Creation: Get Started with <span className="highlight">EditAir</span>
        </div>
        <div className="tabs">
          {Object.keys(videoUrls).map((tab) => (
            <div
              key={tab}
              className={`tab2 ${activeTab === tab ? 'active' : ''}`}
              onClick={() => {
                pauseVideo(); // Pause current video
                setActiveTab(tab); // Set active tab which will change the video source
                setTimeout(playVideo, 0); // Play new video after source has changed
              }}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="tab-description">
          {tabDescriptions[activeTab]}
        </div>
      </div>
    </div>
  );
};

export default Walkthrough;
