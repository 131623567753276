import axios from "axios";
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import AuthContext from '../AuthContext';
import { logout } from '../services/auth';
import { tiktokLogout, youtubeLogout } from "../services/auth";
import { tiktokLogin, youtubeLogin } from "../services/auth";
import { getAccountInfo } from '../services/api';
import { getReferralLink, getAffiliateData } from '../services/api';  // Import the getAffiliateData function
import StripeContainer from '../components/StripeContainer';
import { getSubscriptionStatus } from '../services/api';
import './NewProfile.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const NewProfile = () => {
  const [activeSection, setActiveSection] = useState('Subscription');
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useState(null);
  const [usageLimit, setUsageLimit] = useState(0);

  //pricing section variables
  const [subscription, setSubscription] = useState(null);

  const subscriptionTypes = {
    'free': { name: 'Free', minutes: 60 },
    'prod_O8wYwjy7Op6eSU': { name: 'Editair Premium', minutes: 240 },
    'prod_O8wcbG8gumzDs1': { name: 'Editair Unlimited', minutes: 1000 },
  };

  const location = useLocation();
  const [tikTokAvatarUrl, setTikTokAvatarUrl] = useState("");
  const [tikTokUsername, setTikTokUsername] = useState("");
  const [tikTokNickname, setTikTokNickname] = useState("");
  const [isTikTokConnected, setIsTikTokConnected] = useState(false);
  const [isTikTokLoading, setIsTikTokLoading] = useState(true);

  // set same properties for youtube
  const [youtubeAvatarUrl, setYoutubeAvatarUrl] = useState("");
  const [youtubeUsername, setYoutubeUsername] = useState("");
  const [youtubeCustomUrl, setYoutubeCustomUrl] = useState("");
  const [isYoutubeConnected, setIsYoutubeConnected] = useState(false);
  const [isYoutubeLoading, setIsYoutubeLoading] = useState(true);
  
  //affiliate stuff
  const [referralLink, setReferralLink] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [referredUsers, setReferredUsers] = useState(0);  // Add state for the referred users count
  const [earnings, setEarnings] = useState(0);  // Add state for the earnings
  const [clickedSection, setClickedSection] = useState(null);


  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const response = await getAccountInfo();
        if (response.result === 'success') {
          console.log('Account Info:', response.accountInfo);
          setAccountInfo(response.accountInfo);
          console.log('Subscription Type:', response.accountInfo.subscription_type);

          switch (response.accountInfo.subscription_type) {
            case 'free':
              setUsageLimit(120);
              break;
            case 'prod_O8wYwjy7Op6eSU':
              setUsageLimit(300);
              break;
            case 'prod_O8wcbG8gumzDs1':
              setUsageLimit(1440);
              break;
            default:
              setUsageLimit(0);
          }
        }
      })();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const subscriptionStatus = await getSubscriptionStatus();
        if (subscriptionStatus.result === 'success') {
          console.log('Subscription status:', subscriptionStatus.subscription);
          setSubscription(subscriptionStatus.subscription);
        }
      })();
    }
  }, [isLoggedIn]);

  const handleUpdateSubscription = () => {
    // Redirect to the subscription update page
    window.location.href = 'https://billing.stripe.com/p/login/6oEcPC5TScMvdnWcMM';
  };

  const sections = ['Subscription', 'Usage', 'Connected Accounts', 'Editair Affiliates'];
  const sectionRefs = {
    'Subscription': useRef(null),
    'Usage': useRef(null),
    'Connected Accounts': useRef(null),
    'Editair Affiliates': useRef(null),
  };

  const handleLogout = async () => {
    const response = await logout();

    if (response.result === 'success') {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      console.error(response.message);
    }
  };

  const handleScroll = () => {
    let currentSection = 'Subscription';
    
    // Convert 10vh to pixels. 1vh is 1% of the viewport height.
    const offset = window.innerHeight * 0.1;
    
    for (const section of sections) {
      const elem = sectionRefs[section].current;
      if (elem.getBoundingClientRect().top <= offset) {
        currentSection = section;
      }
    }
    setActiveSection(currentSection);
  };

  const handleSetActiveSection = (section) => {
    setActiveSection(section);
    setClickedSection(section);
    sectionRefs[section].current.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => setClickedSection(null), 200); // Reset after 200ms
  };
  
  
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleTikTokLogin = async () => {
    const response = await tiktokLogin();

    if (response.result === "success") {
      // Handle a successful TikTok login (e.g., update the app state)
      window.location.href = response.auth_url;
    } else {
      console.error(response, "Error while connecting to tiktok");
    }
  };

  const handleTikTokLogout = async () => {
    const response = await tiktokLogout()
    if (response.result === "success") {
      console.log("Successfully logged out of youtube");
    } else {
      console.error(response, "Error while logging out of youtube");
    }
    setIsTikTokConnected(false);
  }

  const handleYoutubeLogin = async () => {
    const response = await youtubeLogin();

    if (response.result === "success") {
      // Handle a successful TikTok login (e.g., update the app state)
      window.location.href = response.auth_url;
    } else {
      console.error(response, "Error while connecting to tiktok");
    }
  };

  const handleYoutubeLogout = async () => {
    const response = await youtubeLogout();
    if (response.result === "success") {
      console.log("Successfully logged out of youtube");
    } else {
      console.error(response, "Error while logging out of youtube");
    }
    setIsYoutubeConnected(false);
  }

  useEffect(() => {
    const fetchTikTokStatusAndInfo = async () => {
      setIsTikTokLoading(true);

      const session = localStorage.getItem("session");
      const config = {
        headers: {
          session: session,
        },
      };
      axios
        .get(`${API_BASE_URL}/api/tiktok-creator-info`, config)
        .then((response) => {
          setTikTokAvatarUrl(response.data.creator_avatar_url);
          setTikTokUsername(response.data.creator_username);
          setTikTokNickname(response.data.creator_nickname);
          setIsTikTokConnected(true);
          // ... other settings if necessary
          setIsTikTokLoading(false);
        })
        .catch((error) => {
          // Handle error case
          setIsTikTokLoading(false);
          if (error.response && error.response.data) {
            console.error("Error:", error.response.data.message);
          } else {
            console.error("An error occurred:", error.message);
          }
        });
    };

    fetchTikTokStatusAndInfo();
  }, [isLoggedIn]); // Re-fetch when the login status changes

  useEffect(() => {
    const fetchYoutubeStatusAndInfo = async () => {
      setIsYoutubeLoading(true);

      const session = localStorage.getItem("session");
      const config = {
        headers: {
          session: session,
        },
      };
      axios
        .get(`${API_BASE_URL}/api/youtube-creator-info`, config)
        .then((response) => {
          setYoutubeAvatarUrl(response.data.data.thumbnails.default.url);
          setYoutubeUsername(response.data.data.title);
          setYoutubeCustomUrl(response.data.data.customUrl);
          setIsYoutubeConnected(true);
          setIsYoutubeLoading(false);
        })
        .catch((error) => {
          // Handle error case
          setIsYoutubeLoading(false);
          if (error.response && error.response.data) {
            console.error("Error:", error.response.data.message);
          } else {
            console.error("An error occurred:", error.message);
          }
        });
    };

    fetchYoutubeStatusAndInfo();
  }, [isLoggedIn]); // Re-fetch when the login status changes

  
  const handleGetReferralLink = async () => {
    const response = await getReferralLink();
    if (response.result === 'success') {
      setReferralLink(response.link);
      const affiliateData = await getAffiliateData();
      if (affiliateData.result === 'success') {
        setReferredUsers(affiliateData.referredUsers);
        setEarnings(affiliateData.earnings);
      }
    } else {
      console.error(response.message);
    }
  };


  // ...same as before
return (
    <div className="new-profile-container">
      <div className="profile-menu">
      <div className="menu-items">
        {sections.map((section) => (
          <div key={section} 
                onClick={() => handleSetActiveSection(section)} 
                className={`${activeSection === section ? 'active' : ''} ${clickedSection === section ? 'clicked' : ''}`}>
            {section}
          </div>     
        ))}
      </div>

        
      </div>
      <div className="profile-content">
        
        {sections.map((section) => (
          <div key={section} ref={sectionRefs[section]} className="content-section">
              <h2>{section}</h2>
              {section === 'Subscription' && (
                <div className="outer-subscription-container">
                <div className="subscription-container">
                {subscription && subscription !== 'free' && subscription !== 'Free' ? (
            <div className="subscription-info">
              <p className="subscription-type">
                You are subscribed to: {subscriptionTypes[subscription].name}
              </p>
              <p>
                Monthly Usage Limit: {subscriptionTypes[subscription].minutes} minutes
              </p>
              <button onClick={handleUpdateSubscription} className="update-button">
                Update Subscription
              </button>
            </div>
          ) : (
            <StripeContainer />
          )}
          
                </div>
              </div>
            )}
            {section === 'Usage' && (
              <div>
                {accountInfo ? (
                    <div className="info-box">
                        {/* Display the account information */}
                        <p>Total Usage: {accountInfo.total_minutes_processed} minutes</p>
                        <p>This Month Upload Usage: {accountInfo.monthly_minutes_processed} minutes</p>
                        

                        {accountInfo && usageLimit > 0 ? (
                        <div className="usage-bar-container">
                            <div className="usage-bar" style={{ 
                            width: `${(accountInfo.monthly_minutes_processed / usageLimit) * 100}%`, 
                            }} />
                        </div>
                        ) : null}
                    </div>
                    ) : (
                    <p>Loading...</p>
                    )}
              </div>
            )}
            {section === 'Connected Accounts' && (
              <div className="connected-accounts-container">
                <>
                    {isTikTokLoading ? (
                        <div className="loading-container">
                        <div className="spinner-tok"></div>
                        </div>
                    ) : isTikTokConnected ? (
                        <div>
                        <h2 className="tiktok-title">Your Connected TikTok Account:</h2>
                        <div className="tiktok-connected-info">
                            <img src={tikTokAvatarUrl} alt="TikTok Avatar" />
                            <div>
                            <p>Username: {tikTokUsername}</p>
                            <p>Nickname: {tikTokNickname}</p>
                            </div>
                        </div>
                        <button className="nav-link nav-link-tiktok" onClick={() => handleTikTokLogout()}>
                            Disconnect Tiktok
                        </button>
                        </div>
                    ) : (
                        <button
                        className="nav-link nav-link-tiktok"
                        onClick={() => handleTikTokLogin()}
                        >
                        Continue with TikTok
                        </button>
                    )}
                    </>
                    <>
                    {isYoutubeLoading ? (
                        <div className="loading-container">
                        <div className="spinner-tok"></div>
                        </div>
                    ) : isYoutubeConnected ? (
                        <div>
                        <h2 className="tiktok-title">Your Connected Youtube Account:</h2>
                        <div className="tiktok-connected-info">
                            <img src={youtubeAvatarUrl} alt="TikTok Avatar" />
                            <div>
                            <p>Username: {youtubeUsername}</p>
                            <p>CustomUrl: {youtubeCustomUrl}</p>
                            </div>
                        </div>
                        <button className="nav-link nav-link-tiktok" onClick={() => handleYoutubeLogout()}>
                            Disconnect Youtube
                        </button>
                        </div>
                    ) : (
                        <button
                        className="nav-link nav-link-tiktok"
                        onClick={() => handleYoutubeLogin()}
                        >
                        Continue with Youtube
                        </button>
                    )}
                </>
              </div>
            )}
            {section === 'Editair Affiliates' && (
              <div className="outer-affiliates-container">
              
              <div className="affiliates-container">
                <button className = 'affiliate-info-button' onClick={() => setShowInfo(!showInfo)}>Affiliate Program Info</button>
                <div className={`affiliates-info ${showInfo ? 'show' : ''}`}>
                <h1>Editair Affiliate Program</h1>
              <p>The Editair Affiliate Program is an exciting opportunity for content creators, bloggers, influencers, and technology enthusiasts to earn commissions by promoting our advanced video editing platform, Editair.</p>
        
              <h2>How It Works</h2>
              <p>By clicking the button below, you'll be entering our affiliate program and receive a unique affiliate link. Share this link on your website, blog, social media posts, videos, or any other digital platform where you engage with your audience. When someone clicks on your link and signs up for an Editair subscription, you earn a 30% commission for the first two months of their subscription.</p>
        
              <h2>Benefits</h2>
              <ul>
                <li>Competitive Commission Rates: We offer a generous commission rate of 30% for each new Editair subscription that comes through your affiliate link for the first two months.</li>
                <li>Access to Promotional Material: We provide you with a range of promotional materials including banners, social media posts, and email templates to make your marketing efforts as easy and effective as possible.</li>
                <li>Real-Time Tracking: Through our affiliate dashboard, you can monitor clicks, conversions, and commissions in real time. These metrics will appear below once you have started to accumulate them.</li>
                <li>Monthly Payouts: Your commissions will be paid out on a monthly basis via PayPal.</li>
              </ul>
        
              <h2>Who Can Join?</h2>
              <p>Anyone with an audience that could benefit from our platform is welcome to join the Editair Affiliate Program. We'd love to have you onboard!</p>
        
              <p>Join Editair's Affiliate Program today and start earning! Simply click the button below to reveal your unique affiliate link and start promoting Editair.</p>
              </div>
        
              <div className="affiliates-action-container">
                  <button className="affiliates-button" onClick={handleGetReferralLink}>{referralLink ? 'Share Your Personal Link To Earn!' : 'Reveal Your Referral Link'}</button>
                  {referralLink && (
          <>
            <p>{referralLink}</p>
            <p>Number of Referred Users: {referredUsers.length}</p>
            <p>Monthly Earnings:</p>
            <ul>
              {Object.entries(earnings).map(([month, earnings]) => (
                <li key={month}>{month}: {earnings}</li>
              ))}
            </ul>
          </>
        )}
                </div>
              </div>
            </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
  // ...same as before
  
};

export default NewProfile;
