import React from 'react';
import termsHtml from '../ToSHTML';

const TermsOS = () => {
  return (
    <>
      <div className="terms">
        <h1>Terms of Service</h1>
        <div
          className="terms-content"
          dangerouslySetInnerHTML={{ __html: termsHtml}}
        />
      </div>
    </>
  );
};

export default TermsOS;