import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getUploads, getClips, getLastUpdated, checkClipStatus, getProcessingStatus, saveSurveyResponses } from '../services/api';
import { getProcessingStatusFirebase, checkSignupModalCompleted } from '../services/firebase_functions';
import logo from '../components/icon.png'
import UploadModal from '../components/UploadModal';
import FeedbackModal from '../components/FeedbackModal';
import './UploadList.css';

const UploadList = () => {
  const [uploads, setUploads] = useState([]);
  const [clipsGenerated, setClipsGenerated] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState([]);
  const [activeTab, setActiveTab] = useState('All');
  const [feedbackResponses, setFeedbackResponses] = useState({}); // state to store the feedback responses


  const fetchProcessingStatus = async () => {

    const promises = uploads.map(async (upload, i) => {
        const response = { processing_status: await getProcessingStatusFirebase(upload.filename) };

        return (response.processing_status.dequeued && !clipsGenerated[i]);
    });
    const newIsProcessing = await Promise.all(promises);
    setIsProcessing(newIsProcessing);
    localStorage.setItem('processingStatus', JSON.stringify(newIsProcessing));
};



  useEffect(() => {
    console.log("Current processing status:", isProcessing);
  }, [isProcessing]);
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

  useEffect(() => {
    const fetchFeedbackModalStatus = async () => {
      try {
        // Use the function from firebase_functions.js to check if the signup modal has been completed
        const hasCompletedSignupModal = await checkSignupModalCompleted();
        // If the signup modal has not been completed, set the modal to open
        setFeedbackModalOpen(!hasCompletedSignupModal);
      } catch (error) {
        console.error('Error checking signup modal status:', error);
        // Handle the error appropriately, perhaps by setting an error message in state
      }
    };

    // Call the function to check the feedback modal status
    fetchFeedbackModalStatus();
  }, []);


  const openModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    fetchProcessingStatus();
    const intervals = [];
    uploads.forEach(upload => {
      const interval = setInterval(async () => {
        const response = { processing_status: await getProcessingStatusFirebase(upload.filename) };  
        // Copy current processing state array
        setIsProcessing(prevIsProcessing => {
          const newIsProcessing = [...prevIsProcessing];
          const uploadIndex = uploads.indexOf(upload);
          
          if (response.processing_status.dequeued && !clipsGenerated[uploadIndex]) {
            newIsProcessing[uploadIndex] = true;
          } else {
            newIsProcessing[uploadIndex] = false;
          }
          
          return newIsProcessing;
        });
      }, 10000);
  
      intervals.push(interval);
    });
  
    // Cleanup: Clear intervals when the component unmounts
    return () => intervals.forEach(interval => clearInterval(interval));
  }, [uploads, clipsGenerated]);  // Add clipsGenerated to the dependency array

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUploads = async () => {
      setIsLoading(true);
      const response = await getUploads();
    
      if (response.result === 'success') {
        const sortedUploads = response.uploads.sort((a, b) => new Date(b.date_uploaded) - new Date(a.date_uploaded));
        // Remove chapters and script from each upload
        const uploadsToStore = sortedUploads.map(upload => {
          const { chapters, script, ...rest } = upload;
          return rest;
        });
        console.log(response)
        setUploads(sortedUploads);
        await fetchProcessingStatus();
        // Cache the uploads and the last fetch time in Local Storage
        localStorage.setItem('uploads', JSON.stringify(uploadsToStore));
        localStorage.setItem('lastFetchTime', Date.now());
      } else {
        setMessage(response.message);
      }
      setIsLoading(false);
    };

    const fetchIfNeeded = async () => {
  
      const lastUpdatedResponse = await getLastUpdated();
      const lastFetchTime = localStorage.getItem('lastFetchTime');
      const cachedProcessingStatus = JSON.parse(localStorage.getItem('processingStatus'));
    
      if (!lastFetchTime || lastUpdatedResponse.last_updated * 1000 > lastFetchTime) {
        await fetchUploads();
        await fetchProcessingStatus();
      } else {
        // Otherwise, use the cached uploads
        const cachedUploads = JSON.parse(localStorage.getItem('uploads'));
        setUploads(cachedUploads);
    
        // Use the cached processing status if it exists
        if (cachedProcessingStatus) {
          setIsProcessing(cachedProcessingStatus);
        } else {
          await fetchProcessingStatus();
        }
      }
    };
    
    fetchIfNeeded();
  }, []);

  const closeModal = () => {
    setModalOpen(false);
 
  };

  // Add this new useEffect hook
  useEffect(() => {
    const checkClipsGenerated = () => {
      console.log('uploads', uploads);
      const clipsStatus = uploads.map(upload => {
        console.log(`Checking upload ${upload.id}, edited: `, upload.edited);
        return upload.edited && upload.edited.length > 0; // We consider 'edited' property now
      });
      console.log("Clips Status: ", clipsStatus);
      setClipsGenerated(clipsStatus);
    };

    // Check for clips generated only if there are uploads
    if (uploads.length > 0) {
      checkClipsGenerated();
    }
  }, [uploads]);

  const handleThumbnailClick = (videoId, videoUrl, filename, index) => {
    if (clipsGenerated[index]) {
      navigate(`/clips/${filename}`, { state: { filename } });
    } else if (isProcessing[index]) {
      navigate(`/processing/${filename}`, { state: { videofile: filename } });
    } else {
      navigate(`/new-clip-settings/${filename}`, { state: { videoUrl, filename } });
    }
  };
  

  let filteredUploads;

switch(activeTab) {
  case 'NotGenerated':
    filteredUploads = uploads.filter((upload, index) => !clipsGenerated[index] && !isProcessing[index]);
    break;
  case 'Processing':
    filteredUploads = uploads.filter((_, index) => isProcessing[index]);
    break;
  case 'Generated':
    filteredUploads = uploads.filter((_, index) => clipsGenerated[index]);
    break;
  default:
    // 'All' tab or any other case
    filteredUploads = uploads;
}

  return (
    <div className="landing1">
      <UploadModal isOpen={isModalOpen} onClose={closeModal} />
      <FeedbackModal 
        isOpen={isFeedbackModalOpen} 
        onClose={() => setFeedbackModalOpen(false)}
      />

      {message && <p>{message}</p>}
    <div className='dashboard-container'>
      <div className="status-tabs">
  <button className={activeTab === 'All' ? 'selected' : ''} onClick={() => setActiveTab('All')}>All Uploads</button>
  <button className={activeTab === 'NotGenerated' ? 'selected' : ''} onClick={() => setActiveTab('NotGenerated')}>Not Generated</button>
  <button className={activeTab === 'Processing' ? 'selected' : ''} onClick={() => setActiveTab('Processing')}>Processing</button>
  <button className={activeTab === 'Generated' ? 'selected' : ''} onClick={() => setActiveTab('Generated')}>Clips Generated</button>
</div>

      {isLoading ? (
        <div className="spinner-container">
          <div className='spinner'></div>
          <img className="pulsing-logo" src={logo} alt="Logo" />
          <div className="loading-text">
            Hang tight, we're getting your clips ready...
          </div>
        </div>
      ) : (
        

        
        <div className="upload-container">
          
          <div className="upload-grid">
            <div className="upload-item first-upload-item">
            <div className="upload-thumbnail upload-icon-container" onClick={openModal}>
            <div style={{fontSize: '5em' }}>
              <i className="fas fa-arrow-up"></i>
            </div>



              </div>
            Upload
          </div>
          {filteredUploads.map((upload) => {
  const originalIndex = uploads.indexOf(upload);
  
  let className = 'upload-item';
  
  if (clipsGenerated[originalIndex]) {
    className += ' clips-generated';
  } else if (isProcessing[originalIndex]) {
    className += ' processing';
  }

  return (
    <div className={className} key={upload.id}>
      <div
        className="upload-thumbnail"
        id={`thumbnail-${originalIndex}`}
        onClick={() => handleThumbnailClick(upload.id, upload.url, upload.filename, originalIndex)}
        style={{ backgroundImage: `url(${upload.thumbnailUrl})` }}
      ></div>
      <div className="upload-filename">{upload.original_filename}</div>
      {clipsGenerated[originalIndex] && (
        <div className="clips-generated-text">Clips Generated</div>
      )}
      {isProcessing[originalIndex] && !clipsGenerated[originalIndex] && (
        <div className="processing-text">Processing...</div>
      )}
    </div>
  );
})}


        </div>
      </div>
      )}
    </div>
    </div>
  );
};

export default UploadList;