import React, { useState } from 'react';
import './FeaturesCarousel.css';

const FeaturesCarousel = () => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);



  const features = [
    {
      name: 'IntellectSearch',
      icon: 'fas fa-search',
      content: 'Search beyond titles and find clips based on emotion, tone, or subject.',
      readMore: 'Intellect Search uses a combination of AI and machine learning to analyze the content of your video and generate a transcript. This transcript is then used to search for clips based on the content of the video, rather than just the title. This allows you to find clips based on the emotion, tone, or subject of the video, rather than just the title.',
    },
    {
      name: 'Multi-Resolution',
      icon: 'fas fa-tv',
      content: 'Generate clips in varying resolutions, catering to your needs, up to 4K.',
    },
    {
      name: 'Media Resizing',
      icon: 'fas fa-expand-arrows-alt',
      content: 'Transform horizontal footage into 9:16 format with Blur Crop and Smart Crop.',
    },
    {
      name: 'Direct Posting',
      icon: 'fas fa-paper-plane',
      content: 'Connect with TikTok and post your clips seamlessly and directly from Editair.',
    },
    {
      name: 'Custom Captions',
      icon: 'fas fa-closed-captioning',
      content: 'Personalize captions with custom font, color, size, and more.',
    },
    {
      name: 'Multi-Language',
      icon: 'fas fa-language',
      content: 'Support for multiple languages, including English, Spanish, French, German, Italian, and Portuguese.',
    },
    {
      name: 'Custom Backdrop',
      icon: 'fas fa-layer-group',
      content: 'Position your speaker against any backdrop with Eddie\'s dynamic background creation.',
    },
  ];

  const visibleFeatures = 4; // Number of features visible at a time
  const featureWidth = 20; // The min-width you set for .feature-item in your CSS

  const handleNext = () => {
    setCurrentFeatureIndex((prevIndex) =>
      prevIndex === features.length - visibleFeatures ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentFeatureIndex((prevIndex) =>
      prevIndex === 0 ? features.length - visibleFeatures : prevIndex - 1
    );
  };


  return (
    <div className="features-carousel">
    <h2 className="carousel-title">More ways to <span className="highlight">upgrade</span> your toolkit and <span className="highlight">grow</span> your reach</h2>
      <div className="features-container">
        <div className="features-row" style={{ transform: `translateX(-${currentFeatureIndex * featureWidth}%)` }}>
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <i className={`${feature.icon} feature-icon`}></i>
              <h3>{feature.name}</h3>
              <p>{feature.content}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="arrow-buttons">
        <button className="arrow-button modern" onClick={handlePrev}>&lt;</button>
        <button className="arrow-button modern" onClick={handleNext}>&gt;</button>
      </div>
    </div>
  );
};

export default FeaturesCarousel;