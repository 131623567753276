import React, { useState } from 'react';
import './FAQ.css';

const FAQSection = () => {
  const [faqOpen, setFaqOpen] = useState(null);

  const FAQData = [
    { 
      question: 'How does EditAir work?', 
      answer: "EditAir combines advanced AI algorithms with a user-friendly interface to automate the creation of short-form clips from long-form content. Our proprietary segmentation algorithms and machine learning models help you effortlessly create and post engaging clips across social media platforms."
    },
    { 
      question: 'How do I get started?', 
      answer: 'Getting started is simple! Just sign up on our website and you’re good to go. No beta keys required.'
    },
    { 
      question: 'How does the AI choose clips?', 
      answer: 'Our AI leverages proprietary segmentation algorithms and data from social media trends to identify topic shifts and crucial moments in your video. Additionally, Smart Crop technology uses facial detection to dynamically frame the active speaker, ensuring optimal visual focus.'
    },
    { 
      question: 'What languages do you support?', 
      answer: 'EditAir supports transcription and subtitling in over 50 languages, including but not limited to English, Spanish, French, German, Portuguese, and Italian.' 
    },
    { 
      question: 'Can I add subtitles?', 
      answer: 'Absolutely! Subtitles can significantly increase viewer engagement. From your dashboard, select the clip you want to edit and add subtitles in your desired language.' 
    },
    { 
      question: 'What’s new in EditAir’s technology?', 
      answer: 'We are continually innovating and have recently added features like individualized machine learning models for optimized posting and data-driven segmentation based on your post-performance analytics.' 
    },
    { 
      question: 'Where can I ask more questions?', 
      answer: 'You can reach us via email at admin@editair.app or join our Discord community to stay updated and interact with other users.' 
    }
  ];
  

  return (
    <div className="faq-section">
      <h2 className="faq-title">Questions & Answers</h2>
      {FAQData.map((faq, index) => (
        <FAQItem 
          key={index} 
          question={faq.question} 
          answer={faq.answer} 
          index={index} 
          faqOpen={faqOpen} 
          setFaqOpen={setFaqOpen} 
        />
      ))}
    </div>
  );
};

const FAQItem = ({ question, answer, index, faqOpen, setFaqOpen }) => {
  const isOpen = faqOpen === index;

  return (
    <div
      className={`faq-item ${isOpen ? 'faq-open' : ''}`}
      onClick={() => setFaqOpen(isOpen ? null : index)}
    >
      <div className="faq-question">
        {question}
        <span className={`arrow ${isOpen ? 'up' : 'down'}`} />
      </div>
      <div className="faq-answer">{isOpen && answer}</div>
    </div>
  );
};

export default FAQSection;
