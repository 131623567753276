import React, { useState, useContext } from 'react';
import Navbar from '../components/Navbar';
import { login, loginWithGoogle } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import GoogleSignIn from '../components/GoogleSignIn';

import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const validateForm = () => {
    let regex = /\S+@\S+\.\S+/;
    if (!regex.test(email)) {
      setMessage("Invalid email format");
      return false;
    }
    if (password.length < 8) {
      setMessage("Password should be at least 8 characters long");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const response = await login(email, password);
      if (response.result === 'success') {
        setMessage('Logged in successfully');
        setIsLoggedIn(true);
        navigate('/uploads');
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage("Something went wrong");
    }
  };

  const handleGoogleSignIn = async (user) => {
    try {
      const referralId = new URLSearchParams(window.location.search).get('ref');
      console.log("referal id", referralId);
      const idToken = await user.getIdToken();
      const response = await loginWithGoogle(idToken, referralId);
      if (response.result === 'success') {
        setMessage('Logged in successfully with Google');
        setIsLoggedIn(true);
        navigate('/uploads');
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage("Something went wrong with Google Sign-In");
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <h1>Login</h1>

        <div className="google-signin-container">
          <GoogleSignIn onSignIn={handleGoogleSignIn} />
        </div>

        <div className="separator">
          <hr />
          <span>OR</span>
          <hr />
        </div>

        <form onSubmit={handleSubmit} className="login-form">
          <label htmlFor="email" className="login-label">Email:</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
          <label htmlFor="password" className="login-label">Password:</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <button type="submit" className="login-button">Login</button>
        </form>
        <a href="/forgot-password" className="forgot-password-link">Forgot your password?</a>
        {message && <p>{message}</p>}

        <p className="memo">Users that have signed up with Google need to login with Google </p>
      </div>
    </div>
  );
};

export default Login;