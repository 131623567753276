import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();

  const scrollToTopAndNavigate = (event, path) => {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      navigate(path);
    }, 500);
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
        <a href="https://discord.gg/xzkVWRKvZ3" target="_blank" rel="noreferrer" className="footer-link footer-link-button">
            Join Editair Community
          </a>
          <Link to="/contact-us" className="footer-link" onClick={(event) => scrollToTopAndNavigate(event, '/contact-us')}>
            Contact Us
          </Link>
          <a href="#!" className="footer-link" onClick={(event) => scrollToTopAndNavigate(event, '/about')}>
            About Us
          </a>
          <Link to="/terms" className="footer-link" onClick={(event) => scrollToTopAndNavigate(event, '/terms')}>
            Terms of Service
          </Link>
          <Link to="/privacy-policy" className="footer-link" onClick={(event) => scrollToTopAndNavigate(event, '/privacy-policy')}>
            Privacy Policy
          </Link>

        </div>
        <div className="footer-social">
        <div className="social-icons">
          <a href="https://www.instagram.com/editair.app/" target="_blank" rel="noreferrer" className="social-link">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://twitter.com/EditairApp" target="_blank" rel="noreferrer" className="social-link">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </div>
        <p>&copy; {new Date().getFullYear()} EditAir Inc.</p>
        <div className="social-icons">
          <a href="https://www.youtube.com/@editairapp" target="_blank" rel="noreferrer" className="social-link">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://linkedin.com/company/editairapp" target="_blank" rel="noreferrer" className="social-link">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
