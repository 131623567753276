import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlay, faPause, faGripLinesVertical } from '@fortawesome/free-solid-svg-icons';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import './VideoPlayer.css';

const secondsToHms = (d) => {
  const isNegative = d < 0;
  d = Math.abs(d); // Take the absolute value for calculation
  const m = Math.floor(d / 60);
  const s = Math.floor(d % 60);
  return `${isNegative ? "-" : ""}${m < 10 ? "0" : ""}${m}:${s < 10 ? "0" : ""}${s}`;
};

const sliderStyle = {
  position: 'relative',
  width: '100%'
};

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: '25px',  // Increased from original
  marginTop: '2.5px',
  borderRadius: '10px',
  backgroundColor: '#ede2f3',
};

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: 'absolute',
        height: 30,
        zIndex: 1,
        backgroundColor: '#c19cdb',
        borderRadius: 5,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
        border: '4px solid #420c68',
      }}
      {...getTrackProps()}
    />
  );
}

const Handle = ({ handle: { id, value, percent }, getHandleProps, setHoverState }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        marginLeft: -5,
        marginTop: 0,
        zIndex: 2,
        width: 10,
        height: 30,
        display: 'flex',             // Add flex display
        alignItems: 'center',        // Vertically center the inner div
        justifyContent: 'center',    // Horizontally center the inner div
        cursor: 'pointer',
        backgroundColor: '#420c68',  // Dark Purple
        color: '#111',
        transform: isHover ? 'scale(1.2)' : 'none', // Scale the handle on hover
        transition: 'transform 0.3s', // Add transition effect
        fontFamily: 'Arial',
        border: 'transparent',       // Corrected typo 'transperent' to 'transparent'
        borderRadius: '3px'
      }}
      {...getHandleProps(id)}
      onMouseEnter={() => {
        setIsHover(true);
        setHoverState(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setHoverState(false);
      }}
    >

      <div
        style={{
          fontFamily: 'Poppins',
          fontSize: 11,
          position: 'absolute',
          bottom: '-30px',
          left: '50%',
          transform: 'translateX(-50%)',
          color: '#3b074b',
          fontWeight: 'bold'
        }}
      >
        {secondsToHms(value)}
      </div>
    </div>
  );
};



const VideoPlayer = ({ src, start: startProp, end: endProp, trim, setTrim }) => {

  const videoRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [hoverTime, setHoverTime] = useState(null);
  const [hoverHeight, setHoverHeight] = useState(null)
  const [isHandleHovered, setHandleHovered] = useState(false);
  const [start, setStart] = useState(startProp); // Assuming the prop name is startProp
  const [end, setEnd] = useState(endProp); // Assuming the prop name is endProp
  const buffer = 10;
  const [domainStart, setDomainStart] = useState(start === 0 ? 0 : Math.max(0, start - buffer));
  const [domainEnd, setDomainEnd] = useState(end + buffer);
  const [isNegativeHoverTime, setIsNegativeHoverTime] = useState(false);

  useEffect(() => {
    setDomainStart(start === 0 ? 0 : Math.max(0, start - buffer));
    setDomainEnd(end + buffer);
  }, [start, end]);

  // Update the state within your handle callbacks
  useEffect(() => {
    setTrim([start, end]);
  }, [start, end]);

  useEffect(() => {
    setStart(startProp);
    setEnd(endProp);
  }, [startProp, endProp]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = trim[0];
    }
  }, [trim]);

  useEffect(() => {
    if (src !== prevSrcRef.current) {
      // Update the domain values with the buffer
      setDomainStart(start === 0 ? 0 : Math.max(0, start - buffer));
      setDomainEnd(end + buffer);

      // Update the trim values with the buffer
      setTrim([start + buffer, end - buffer]);
      if (videoRef.current) {
        videoRef.current.currentTime = trim[0];
      }
      prevSrcRef.current = src; // Update the ref with the current src
    }
  }, [src, trim]);



  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.ontimeupdate = () => {
        const newRelativeTime = videoRef.current.currentTime;
        setProgress(newRelativeTime);

        // Looping logic
        if (playing && newRelativeTime > trim[1]) {
          videoRef.current.currentTime = trim[0];
        }
      };
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.ontimeupdate = null;
      }
    };
  }, [src, trim, start, end, playing]);


  const handleClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left; // Relative X position
    let actualTimestamp = domainStart + (x / rect.width) * (domainEnd - domainStart); // This is the actual time you want to jump to
    // Ensure timestamp stays within the start and end bounds
    actualTimestamp = Math.max(domainStart, Math.min(domainEnd, actualTimestamp));

    if (videoRef.current) {
      videoRef.current.currentTime = actualTimestamp;
      videoRef.current.pause(); // Pause the videos
      setPlaying(false); // Update the playing state to false
    }
  };

  const handleMouseMove = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left; // Relative X position
    let timestamp = domainStart + (x / rect.width * (domainEnd - domainStart));
    // Ensure timestamp stays within the start and end bounds
    timestamp = Math.max(domainStart, Math.min(domainEnd, timestamp));

    setIsNegativeHoverTime(timestamp - trim[0] < 0); // Set the state based on whether the hover time is negative
    setHoverTime(timestamp); // Set the absolute hover time
  };

  
  const togglePlayPause = (event) => {
    if (hoverTime !== null) {
      const rect = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - rect.left; // Relative X position
      let actualTimestamp = domainStart + (x / rect.width) * (domainEnd - domainStart); // This is the actual time you want to jump to
      // Ensure timestamp stays within the start and end bounds
      actualTimestamp = Math.max(domainStart, Math.min(domainEnd, actualTimestamp));

      videoRef.current.currentTime = actualTimestamp;
      videoRef.current.pause();
      setPlaying(false);
    } else {
      if (!playing) {
        if (videoRef.current.currentTime < trim[0] || videoRef.current.currentTime > trim[1]) {
          videoRef.current.currentTime = trim[0];
        }
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setPlaying(!playing);
    }
  };



  const handleTrimChange = (newTrimValues) => {
    const [newStart, newEnd] = newTrimValues;
    setTrim([newStart, newEnd]);  // <-- This is the crucial step
  };

  const handleMouseLeave = () => {
    setHoverTime(null);
  };

  const prevSrcRef = useRef();

  const expandClipStart = () => {
    if (domainStart > 5) {
      setDomainStart(prevDomainStart => prevDomainStart - 5);
    } else {
      setDomainStart(0);
    }
  };

  const expandClipEnd = () => {
    if (videoRef.current && domainEnd < videoRef.current.duration - 5) {
      setDomainEnd(prevDomainEnd => prevDomainEnd + 5);
    } else if (videoRef.current) {
      setDomainEnd(videoRef.current.duration);
    }
  };







  return (
    <div className="VideoPlayer">
      <video ref={videoRef} src={src} />

      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
        {`${secondsToHms(progress - trim[0])} / ${secondsToHms(trim[1] - trim[0])}`}
      </div>

      <div className='VideoControls'>

        <div className="play-pause-container">
          <div className="expand-container">
            <button onClick={expandClipStart} className="expand-btn">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <div className="expand-time-text">5s</div>
          </div>

          <button onClick={togglePlayPause} className="play-pause-btn">
            {playing ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
          </button>

          <div className="expand-container">
            <button onClick={expandClipEnd} className="expand-btn">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <div className="expand-time-text">5s</div>
          </div>
        </div>


        <div
          className="slider-wrapper"
          onClick={handleClick}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          style={{ position: 'relative' }}
        >
          <div
            className="current-playtime-button"
            style={{ left: `${((progress - domainStart) / (domainEnd - domainStart)) * 100}%` }}
          />

          <Slider
            rootStyle={sliderStyle}
            domain={[domainStart, domainEnd]}
            step={0.01}
            mode={2}
            values={trim}
            onUpdate={handleTrimChange}
            onChange={handleTrimChange}
          >
            <Rail>
              {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map(handle => (
                    <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} setHoverState={setHandleHovered} />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>

          {!isHandleHovered && hoverTime !== null && (
            <div
              className="playhead"
              style={{ left: `${((hoverTime - domainStart) / (domainEnd - domainStart)) * 100}%` }}
              onClick={togglePlayPause}
            >
              <div className={`playhead-timestamp ${isNegativeHoverTime ? 'negative-time' : ''}`}>
                {secondsToHms(hoverTime - trim[0])}
              </div>
              <div
                className="playhead-line"
                style={{ height: `${hoverHeight}px` }}
                onMouseEnter={() => setHoverHeight(30)}
                onMouseLeave={() => setHoverHeight(20)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
