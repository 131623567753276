import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from './icon.png';
import AuthContext from '../AuthContext';
import { getAccountInfo } from '../services/api';
import { logout } from '../services/auth';

const Navbar = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [accountInfo, setAccountInfo] = useState(null);
  const [usageLimit, setUsageLimit] = useState(0);
  const [showUsageInfo, setShowUsageInfo] = useState(false);


  const handleClick = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop - 20, // Adding a small offset for better visibility
        behavior: 'smooth',
      });
    } else {
      window.location.href = `/#${sectionId}`;
    }
  };

  const handleLogout = async () => {
    const response = await logout();

    if (response.result === 'success') {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      console.error(response.message);
    }
  };

  const handleScroll = (sectionId) => {
    const section = document.querySelector(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const response = await getAccountInfo();
        if (response.result === 'success') {
          console.log('Account Info:', response.accountInfo);
          setAccountInfo(response.accountInfo);

          switch (response.accountInfo.subscription_type) {
            case 'free':
              setUsageLimit(120);
              break;
            case 'prod_O8wYwjy7Op6eSU':
              setUsageLimit(300);
              break;
            case 'prod_O8wcbG8gumzDs1':
              setUsageLimit(1440);
              break;
            default:
              setUsageLimit(0);
          }
        }
      })();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const features = document.querySelector('#features-nav');
    const pricing = document.querySelector('#pricing-nav');

    if (features) {
      features.onclick = () => handleScroll('#features-showcase');
    }

    if (pricing) {
      pricing.onclick = () => handleScroll('#pricing');
    }
  }, []);

  return (

    <nav className="navbar">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <li className="logo">
          <Link to="/" className="nav-link">
            <img src={logo} alt="EditAIr Logo" className="logo-image" />
          </Link>
      </li>
      {isLoggedIn && accountInfo && usageLimit > 0 ? (
        <div className='usage2-container' onMouseOver={() => setShowUsageInfo(true)} onMouseOut={() => setShowUsageInfo(false)}>
          <div className="usage-icon" >
          <i className="fa fa-clock-o" aria-hidden="true"></i>
          </div>
          <div className="usagehold">
            <div className="usagebar"  
            style={{ 
              width: `${(accountInfo.monthly_minutes_processed / usageLimit) * 100}%`, 
            }} />
          </div>
          {showUsageInfo && <span className="usage-info-text">Monthly Usage: {accountInfo.monthly_minutes_processed}/{usageLimit} minutes <a href="/subscription" className="upgrade-link">Upgrade Now</a></span> }
        </div>
      ) : null}

      
      <div className="hamburger" onClick={toggleMenu}>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
      </div>
      <ul className={`navbar-nav${menuVisible ? ' show' : ''}`}>
        
        {!isLoggedIn && (
          <>
          <Link to="#" className="nav-link" onClick={() => handleClick('features')}>
            Features
          </Link>
          <Link to="#" className="nav-link" onClick={() => handleClick('pricing')}>
            Pricing
          </Link>
          <Link to="/affiliateprogram" className="nav-link" onClick={closeMenu}>
            Affiliates
          </Link>
          <Link to="/learn" className="nav-link" onClick={closeMenu}>
            Learn
          </Link>
          </>
        )}
        <div className="nav-right-links">
          {!isLoggedIn && (
            <>
            <li className="nav-item">
              <Link to="/login" className="nav-link" onClick={closeMenu}>
                Login
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signup" className="nav-link nav-link-signup" onClick={closeMenu}>
                Sign Up
              </Link>
            </li>
          </>
            
          )}
          {isLoggedIn && (
            <>
            <li className="nav-item">
              <Link to="/uploads" className="nav-link" onClick={closeMenu}>
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/profile" className="nav-link" onClick={closeMenu}>
                Account
              </Link>
            </li>
            <li className="nav-item">
              <span className="nav-link" onClick={handleLogout}>
                Logout
              </span>
            </li>
            </>
          )}
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
