import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { signup, loginWithGoogle } from '../services/auth';
import GoogleSignIn from '../components/GoogleSignIn';
import './Signup.css';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state variable for confirm password
  const [referralId, setReferralId] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    if (ref) {
      setReferralId(ref);
    }
  }, []);

  const handleGoogleSignIn = async (user) => {
    try {
      const referralId = new URLSearchParams(window.location.search).get('ref');
      console.log("referral id", referralId);
      const idToken = await user.getIdToken();
      const response = await loginWithGoogle(idToken, referralId); // call the new function
      // Check the response from your backend and update your app's state accordingly.
      if (response.result === 'success') {
        window.location.href = '/login';
      } else {
        alert(response.message);
      }
    } catch (error) {
      alert("Something went wrong with Google Sign-In");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the password and confirm password fields are the same
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return; // Exit the function if passwords don't match
    }

    try {
      console.log('referralId', referralId);
      const response = await signup(email, password, referralId); // Removed betaCode
      console.log('response', response);
      if (response.result === 'success') {
        window.location.href = '/login';
      } else {
        alert(response.message);
      }
    } catch (error) {
      alert('Signup failed');
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form-container">
        <h1>Start editing for free</h1>
        
        <div className="google-signin-container">
          <GoogleSignIn onSignIn={handleGoogleSignIn} />
        </div>

  
        <div className="separator">
          <hr />
          <span>OR</span>
          <hr />
        </div>
  
        <form onSubmit={handleSubmit} className="signup-form">
          <label className="signup-label">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-input"
          />
          <label className="signup-label">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-input"
          />
          <label className="signup-label">Confirm Password:</label>  
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="signup-input"
          />
          <button type="submit" className="signup-button">
            Create Account
          </button>
        </form>
      </div>
    </div>
  );
  
};

export default Signup;