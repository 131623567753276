import { getFirestore } from 'firebase/firestore';
import app from '../firebaseConfig';
import { doc, collection, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // adjust the path as necessary based on your directory structure

const getSessionIdFromLocalStorage = () => {
  return localStorage.getItem('session');
};


const getProcessingStatusFirebase = async (videofile) => {
  try {
    const sessionId = getSessionIdFromLocalStorage();

    if (!sessionId) throw new Error("No session found");

    // Get user associated with the session
    console.log("DB before collection call:", db);
    const sessionDocRef = doc(db, 'sessions', sessionId);
    const docSnapshot = await getDoc(sessionDocRef);    
    const userInfo = docSnapshot.data();
    const user = userInfo['user'];
    const email = user['email'];

    // Get processing status
    const fileDocRef = doc(db, 'users', email, 'uploads', videofile);
    const fileDocSnapshot = await getDoc(fileDocRef);
    

    if (!fileDocSnapshot.exists) {
      throw new Error(`Document does not exist for ${videofile}`);
    }

    const data = fileDocSnapshot.data();

    if (!('processing_status' in data)) {
      throw new Error(`Error: processing_status not found in document for ${videofile}`);
    }

    const processingStatusData = data['processing_status'];

    // Convert the data
    let convertedData = {};

    for (let key in processingStatusData) {
      if (processingStatusData.hasOwnProperty(key)) {
        // Check for 'status' to identify new format
        if (processingStatusData[key] && typeof processingStatusData[key] === 'object' && 'status' in processingStatusData[key]) {
          convertedData[key] = processingStatusData[key].status;
        } else {
          // Old format
          convertedData[key] = processingStatusData[key];
        }
      }
    }
    console.log("Returning from getProcessingStatusFirebase:", convertedData);
    return convertedData;    
  } catch (error) {
    console.error("Failed to check clip status", error);
    throw error;
  }
};


// Function to check if the signup modal survey has been completed
const checkSignupModalCompleted = async () => {
  try {
    const sessionId = getSessionIdFromLocalStorage();

    if (!sessionId) throw new Error("No session found");

    // Get user associated with the session
    const sessionDocRef = doc(db, 'sessions', sessionId);
    const docSnapshot = await getDoc(sessionDocRef);

    if (!docSnapshot.exists()) {
      throw new Error(`Session document does not exist for session ID: ${sessionId}`);
    }

    const userInfo = docSnapshot.data();
    const user = userInfo['user'];
    const email = user['email'];

    // Get the user's document to check for the 'signup_modal' field
    const userDocRef = doc(db, 'users', email);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      throw new Error(`User document does not exist for email: ${email}`);
    }

    const userData = userDocSnapshot.data();

    // Return true if 'signup_modal' field exists, meaning the survey has been completed
    return userData.hasOwnProperty('signup_modal');
  } catch (error) {
    console.error("Failed to check signup modal status", error);
    throw error; // re-throw the error to handle it in the component
  }
};


export { getProcessingStatusFirebase, checkSignupModalCompleted };
