import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SessionProvider } from './SessionContext';
import PrivateRoute from './components/PrivateRoute';
import AuthenticatedRoutes from './components/AuthenticatedRoutes';
import Navbar from './components/Navbar';
import Learn from './pages/Learn';
import BlogPost from './components/BlogPost';

import Landing from './pages/NewLanding';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import FAQ from './pages/FAQ';
import AffiliateProgram from './pages/AffiliateProgram';

import { AuthProvider } from './AuthContext';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOS from './pages/TermsOS';
import ContactUs from './pages/ContactUs';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics } from '@vercel/analytics/react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <AuthProvider>
    <SessionProvider>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/app/*" element={<PrivateRoute />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOS />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/affiliateprogram" element={<AffiliateProgram />}/>
            <Route path="/learn" element =  {<Learn />} />
            <Route path="/blog/:postId" element={<BlogPost />} />
          </Routes>
          <AuthenticatedRoutes />
          <div className='App'>
            <Footer />
            <Analytics /> 
          </div>
        </BrowserRouter>
      </Elements>
    </SessionProvider>
    </AuthProvider>
  );
}

export default App;
