import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const login = async (email, password) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/login`,
      {
        email,
        password,
      },
      {
        withCredentials: true,
      }
    );

    // Store the session ID in localStorage
    localStorage.setItem("session", response.data.session);

    return response.data;
  } catch (error) {
    return { result: "error", message: "An error occurred while logging in." };
  }
};

const signup = async (email, password, referralId) => { // Removed betaCode
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/signup`,
      {
        email,
        password,
        referral_id: referralId, // Include the referralId
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: "error", message: error.response.data.message };
  }
};


const logout = async () => {
  try {
    await axios.post(
      `${API_BASE_URL}/api/logout`,
      {},
      {
        withCredentials: true,
      }
    );

    // Remove sessionID from localStorage after a successful logout
    localStorage.removeItem("session");
    localStorage.removeItem("lastFetchTime");
    localStorage.removeItem("uploads");

    return { result: "success", message: "Logged out successfully" };
  } catch (error) {
    return { result: "error", message: "An error occurred during logout." };
  }
};

const forgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/forgot_password`,
      {
        email,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { result: "error", message: error.response.data.message };
  }
};

const isAuthenticated = () => {
  return document.cookie.includes("session");
};


const tiktokLogin = async () => {
  try {
    const session = localStorage.getItem("session");

    let url = `${API_BASE_URL}/api/oauth/tiktok-login`;

    // This does not follow redirect but just receives the redirect response
    const response = await fetch(url, {
      headers: {
        session: session,
      },
    });

    const res = await response.json();
    console.log(res);
    const auth_url = await res.auth_url;

    return { result: "success", auth_url: auth_url };

  } catch (error) {
    return {
      error,
    };
  }
};


const tiktokLogout = async () => {
  try {
    const session = localStorage.getItem("session");

    let url = `${API_BASE_URL}/api/oauth/tiktok-disconnect`;

    // This does not follow redirect but just receives the redirect response
    const response = await fetch(url, {
      method: "POST",
      headers: {
        session: session,
      },
    });

    const res = await response.json();
    const message = await res.message;

    return { result: "success", message: message };

  } catch (error) {
    return {
      error,
    };
  }
};

const youtubeLogin = async () => {
  try {
    const session = localStorage.getItem("session");

    let url = `${API_BASE_URL}/api/oauth/youtube-login`;

    // This does not follow redirect but just receives the redirect response
    const response = await fetch(url, {
      headers: {
        session: session,
      },
    });

    const res = await response.json();
    const auth_url = await res.auth_url;
    console.log(auth_url);


    return { result: "success", auth_url: auth_url };

  } catch (error) {
    return {
      error,
    };
  }
};

const youtubeLogout = async () => {
  try {
    const session = localStorage.getItem("session");

    let url = `${API_BASE_URL}/api/oauth/youtube-disconnect`;

    // This does not follow redirect but just receives the redirect response
    const response = await fetch(url, {
      method: "POST",
      headers: {
        session: session,
      },
    });

    const res = await response.json();
    const message = await res.message;

    return { result: "success", message: message };

  } catch (error) {
    return {
      error,
    };
  }
};

const loginWithGoogle = async (idToken, referralId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/google-signin`, { idToken, referralId });
    // Store the session ID in localStorage
    localStorage.setItem("session", response.data.session);
    return response.data;
  } catch (error) {
    console.error('Error signing in with Google:', error);
    return { result: 'error', message: 'Something went wrong with Google Sign-In' };
  }
};


export { login, signup, logout, forgotPassword, isAuthenticated, tiktokLogin, tiktokLogout, youtubeLogin, youtubeLogout, loginWithGoogle };