import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LoadingBar from '../components/LoadingBar';
import { checkClipStatus, getProcessingStatus } from '../services/api';
import { getProcessingStatusFirebase } from '../services/firebase_functions';

import './Processing.css';

const Processing = () => {
  const location = useLocation();
  const videofile = location.state.videofile;
  const [isClipsReady, setIsClipsReady] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);



  const stages = [
    {
      label: 'Queued',
      check: status => status.enqueued ? (status.dequeued ? 'completed' : 'inProgress') : 'notStarted',
    },
    {
      label: 'Transcribing',
      check: status => status.transcribing ? (status.transcribed ? 'completed' : 'inProgress') : 'notStarted',
    },
    {
      label: 'Cropping Video',
      check: status => status.cropping ? (status.cropped ? 'completed' : 'inProgress') : 'notStarted',
    },
    {
      label: 'Adding Subtitles',
      check: status => status.subtitling ? (status.subtitled ? 'completed' : 'inProgress') : 'notStarted',
    },
    {
      label: 'Uploading Your Videos',
      check: status => status.uploading ? (status.uploaded ? 'completed' : 'inProgress') : 'notStarted',
    },
  ];

  const [processingStages, setProcessingStages] = useState(stages.map(stage => ({
    label: stage.label,
    status: 'notStarted',
  })));

  useEffect(() => {
    let startTime = Date.now(); // Record the start time
  
    // Define the fetch and update status function
    const fetchAndUpdateStatus = async () => {
      console.log(`Checking clip status for video: ${videofile}`);
      const response = await checkClipStatus(videofile);
      const processingResponse = await getProcessingStatusFirebase(videofile);
      console.log('Initial Processing Status:', processingResponse);
  
      if (processingResponse && processingResponse.estimated_time !== undefined) {
        setEstimatedTime(processingResponse.estimated_time);
      }
  
      // Calculate the time elapsed and remaining time
      let elapsed = (Date.now() - startTime) / 100000; // time in seconds
      let remaining = Math.max(estimatedTime - elapsed, 0);
      setTimeRemaining(Math.ceil(remaining));
  
      if (response.status === 'completed') {
        setIsClipsReady(true);
        const completedStages = stages.map(stage => ({
          label: stage.label,
          status: 'completed',
        }));
        setProcessingStages(completedStages);
      } else {
        const newStages = stages.map(stage => ({
          label: stage.label,
          status: stage.check(processingResponse),
        }));
        setProcessingStages(newStages);
      }
    };
  
    // Immediately invoke the fetch and update status function
    fetchAndUpdateStatus();
  
    // Set the interval to re-fetch every 30 seconds
    const interval = setInterval(fetchAndUpdateStatus, 30000);
    
    return () => clearInterval(interval);  // Cleanup interval when component unmounts
  }, [videofile]);
  

  console.log("videofile in Processing:", videofile);

  return (
    <div className="container">
      <h1>Processing...</h1>
      {!isClipsReady && <LoadingBar />}

      {/* <div className="estimated-time">
      <span className="fa fa-clock-o"></span>
        {timeRemaining !== null && <p>Estimated Time Remaining: {timeRemaining} Minutes</p>}
      </div> */}


      <ul className="processing_status_bar">
        {processingStages.map(stage => (
          <li
            key={stage.label}
            className={stage.status}
          >
            {stage.label}
          </li>
        ))}
      </ul>

      <div className="discord-section">
        <p>You will recieve an email when clips have been generated. While we are working on generating your clips, checkout the <a href="https://discord.com/invite/xzkVWRKvZ3" target="_blank" rel="noreferrer">EditAir Discord</a> to see what users are talking about!</p>
      </div>


      {isClipsReady ? (
        <Link
          to={{
            pathname: `/clips/${videofile}`,
            state: { videofile },
          }}
          className="view-generated-clips"
        >
          View Generated Clips
        </Link>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default Processing;