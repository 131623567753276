import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom to navigate
import affiliateIcon from './featureimages/affiliate-marketing.svg'; // Adjust the path according to your file structure
import './AffiliateProgram.css';

const AffiliateProgram = () => {
  

  return (
    <div className="affiliates-background">
    <div className="affiliates-public">

      <div className="affiliates-public-action-container">
        {/* Sign Up to Start Earning button that routes to the sign-up page */}
        <Link to="/signup">
          <button className="affiliates-public-button">Click Here to Sign Up!</button>
        </Link>
      </div>
      <h1>Become an <span className='aggressive'>EDITAIR</span> Affiliate!</h1>
      <div className="affiliates-public-info">
      <p className='affiliate-hook'>An exciting opportunity for content creators, bloggers, influencers, and tech enthusiasts to earn money by promoting our advanced video editing platform.</p>
      <div className='affiliate-images'>
      <img src={affiliateIcon} alt="Affiliate Icon" className="affiliate-icon" />
      <img src={affiliateIcon} alt="Affiliate Icon" className="affiliate-icon" />
      <img src={affiliateIcon} alt="Affiliate Icon" className="affiliate-icon" />
      </div>
      <div className="benefits">
        <h2>Sign up and start earning!</h2>
        <div className="benefits-row">
            <div className="benefit-item">
            <h3>Earn Money</h3>
            <i class="fas fa-dollar-sign benefit-icon"></i>
            <p>Make 30% comission for each new Editair subscription that comes through your affiliate link for the first two months.</p>
            </div>
            <div className="benefit-item">
            <h3>Brand Material</h3>
            <i class="fas fa-bullhorn benefit-icon"></i>
            <p>We provide you with a range of promotional materials including banners, social media posts, and email templates to make your marketing efforts as easy and effective as possible.</p>
            </div>
            <div className="benefit-item">
            <h3>Real-Time Tracking</h3>
            <i class="fas fa-chart-line benefit-icon"></i>
            <p>Through our affiliate dashboard, you can monitor clicks, conversions, and commissions in real time. These metrics will appear below once you have started to accumulate them.</p>
            </div>
            <div className="benefit-item">
            <h3>Monthly Payouts</h3>
            <i class="fas fa-calendar-alt benefit-icon"></i>
            <p>Your commissions will be paid out on a monthly basis via PayPal.</p>
            </div>
        </div>
        </div>
        <div className="how-it-works">
            <h2>How does it work?</h2>
            <h1> It's as easy as 1 ... 2 ... 3</h1>
            <div className="works-row">
                <div className="works-item">
                <i class="fas fa-user-plus works-icon"></i>
                <h3>Sign Up</h3>
                <p>Create an account to get started!</p>
                </div>
                <div className="works-item">
                <i class="fas fa-link works-icon"></i>
                <h3>Get Your Link</h3>
                <p>Find your unique link in the Affiliates portion of your profile page!</p>
                </div>
                <div className="works-item">
                <i class="fas fa-bullhorn works-icon"></i>
                <h3>Start Promoting</h3>
                <p>Share your link online and start earning!</p>
                </div>
            </div>
            </div>


        <div className="who-can-join">
        <h2>Who Can Join?</h2>
        <p>Anyone with an audience that could benefit from our platform is welcome to join the Editair Affiliate Program. We'd love to have you onboard!</p>
        
        </div>

      </div>

      <div className="affiliates-public-action-container">
        {/* Sign Up to Start Earning button that routes to the sign-up page */}
        <Link to="/signup">
          <button className="affiliates-public-button">Click Here to Sign Up!</button>
        </Link>
      </div>
    </div>
    </div>
  );
};

export default AffiliateProgram;
