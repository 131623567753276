// BlogPosts.js
const blogPosts = [
    {
      id: '1',
      title: 'The EditAir Blog',
      description: 'The EditAir Blog is a place for us to share our thoughts on the future.',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/authenticated-webapp.appspot.com/o/blogs%2F1.png?alt=media&token=5bdc8ae6-97a8-4480-916d-b091d382731b&_gl=1*2iksu0*_ga*MTU2NTE4NzQ3MS4xNjgwNjQ1NDE5*_ga_CW55HF8NVT*MTY5ODAxOTg5OS40ODEuMS4xNjk4MDE5OTA1LjU0LjAuMA..', // local or hosted image path
      author: {
        name: 'Tyler Houchin',
        position: 'Co Founder, CEO',
        imageUrl: 'https://media.licdn.com/dms/image/D5603AQE_o6uvcmXKOw/profile-displayphoto-shrink_800_800/0/1687042456416?e=1703721600&v=beta&t=jI_v-sAX657ktw-Sl1BSvuuzRyOC82pEj_sHFoFV7QU', // URL to the author's image
      },
      date: '2023-10-10'
    },
    {
      id: '2',
      title: 'EditAir Walkthrough',
      description: 'A walkthrough of the EditAir application.',
      imageUrl: 'https://github.com/tyhouch/editair_images/blob/main/a392730a-e770-4c14-8541-4d10089e147f.png?raw=true',
      author: {
        name: 'Tyler Houchin',
        position: 'Co Founder, CEO',
        imageUrl: 'https://media.licdn.com/dms/image/D5603AQE_o6uvcmXKOw/profile-displayphoto-shrink_800_800/0/1687042456416?e=1703721600&v=beta&t=jI_v-sAX657ktw-Sl1BSvuuzRyOC82pEj_sHFoFV7QU', // URL to the author's image
      },
      date: '2023-10-22'

    },
    {
      id: '3',
      title: 'Analyzing Virality',
      description: 'The importance of engagement data in making viral content.',
      imageUrl: 'https://github.com/tyhouch/editair_images/blob/main/viralitypost.png?raw=true',
      author: {
        name: 'Radu Manea',
        position: 'Co Founder, COO',
        imageUrl: 'https://media.licdn.com/dms/image/D5603AQFKopy0unGrgA/profile-displayphoto-shrink_800_800/0/1698862622303?e=1704326400&v=beta&t=oNr3Rjd6gZm-ePJMKLY8j32xDzlobA91bbd-NEN6nq8', // URL to the author's image
      },
      date: '2023-11-04'

    }
    // ... other blog posts
  ];
  
  export default blogPosts;
  