// Learn.js
import React from 'react';
import { Link } from 'react-router-dom';
import blogPosts from '../components/BlogPosts';
import './Learn.css';

const Learn = () => {
  const reversedPosts = [...blogPosts].reverse();

  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
  
    if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  };
  

  return (
    <div>
      <div className="title-section">
        <h1>EditAir Learn</h1>
        <p>Elevate Your Content: A Deep Dive into AI, Video, and Social Media</p>
      </div>
      <div className="blog-grid">
      {reversedPosts.map((post) => (  // Use the reversed array here
        <Link to={`/blog/${post.id}`} key={post.id} className="blog-post-preview">
          <img src={post.imageUrl} alt={post.title} className="blog-post-image" />
          <div className="blog-post-content">
            <p className="blog-post-date">{getTimeAgo(post.date)}</p> {/* Updated to show relative date */}
            <h2 className="blog-post-title">{post.title}</h2>
            <p className="blog-post-description">{post.description}</p>
            <div className="author-info">
              <img src={post.author.imageUrl} alt={post.author.name} className="author-image" />
              <div className="author-details">
                <p className="author-name">{post.author.name}</p>
                <p className="author-position">{post.author.position}</p>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
    </div>
  );
};

export default Learn;