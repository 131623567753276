import { Route, Routes } from 'react-router-dom';
import UploadVideo from '../pages/Upload';
import UploadList from '../pages/UploadList';
import ClipSettings from '../pages/ClipSettings';
import GeneratedClips from '../pages/GeneratedClips';
import Processing from '../pages/Processing';
import Profile from '../pages/Profile';
import UserProfile from '../components/UserProfile';
import Usage from '../components/Usage';
import Subscription from '../components/Subscription';
import ConnectedAccounts from '../components/ConnectedAccounts';
import Affiliates from '../components/Affiliates';
import NewClipSettings from '../pages/NewClipSettings';
import NewProfile from '../pages/NewProfile'

const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/profile" element={<NewProfile />} />
      <Route path="/upload" element={<UploadVideo />} />
      <Route path="/uploads" element={<UploadList />} />
      <Route path="/new-clip-settings/:videoId" element={<NewClipSettings />} />
      <Route path="/clips/:videoId" element={<GeneratedClips />} />
      <Route path="/processing/:videoId" element={<Processing />} />
      <Route path="user-profile" element={<UserProfile />} />
      <Route path="usage" element={<Usage />} />
      <Route path="subscription" element={<Subscription />} />
      <Route path="connected-accounts" element={<ConnectedAccounts />} />
      <Route path="affiliates" element={<Affiliates />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
