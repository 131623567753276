import React, { useRef, useContext, useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import { uploadVideo, uploadYouTubeLink } from '../services/api';
import SessionContext from '../SessionContext';
import './Upload.css';
import { useNavigate } from 'react-router-dom';

const UploadVideo = ({ onClose }) => {
  const fileInput = useRef();
  const [youtubeLink, setYoutubeLink] = useState('');
  const { sessionId } = useContext(SessionContext);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();
  const [selectedFileName, setSelectedFileName] = useState(''); 
  const [progressText, setProgressText] = useState('');
  const [textIndex, setTextIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [slowProgress, setSlowProgress] = useState(false);

  
  
  const auxiliaryTexts = [
    'Getting things ready for you...',
    'Your video is on its way up!',
    'Making sure everything looks good...',
    'Taking a quick look at your video...',
    'Fine-tuning the audio...',
    'Making your video look its best...',
    'Creating a sneak peek for you...',
    'Understanding what your video is about...',
    'You are in the home stretch now...',
    'Getting your workspace ready...',
    'Saving the important details...',
    'Putting on the finishing touches...',
    'Almost done, stay with us!',
    'Getting ready to show you the magic...',
    'Your video is almost live!',
    'Completing the final steps...',
    'Gearing up for the grand reveal...',
    'Adding a sprinkle of awesomeness...',
    'Final checks, almost there...',
    'Smoothing out the last details...',
    'Your masterpiece is nearly ready...',
    'Counting down to awesomeness...',
    'Hold tight, just a moment more...',
    'We are excited too, almost there!',
    'And... voila! Ready to go!'
  ];

  const handleFileChange = (e) => { 
    const files = e.target.files;
    if (files.length > 0) {
      setSelectedFileName(files[0].name);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsUploading(true);
    if (fileInput.current.files.length === 0 && !youtubeLink) {
      alert('Please choose a file to upload or enter a YouTube link');
      setIsUploading(false); 
      return;
    }
    try {
      if (fileInput.current.files.length > 0) {
        await uploadVideo(fileInput.current.files[0], sessionId, setUploadProgress);

      
      } else {
        await uploadYouTubeLink(youtubeLink, sessionId, setUploadProgress);
      }

      if (uploadProgress >= 80) {
        setSlowProgress(true);
      }
      

      onClose();
      window.location.reload();
      //alert('Video uploaded successfully');
      //navigate('/uploads');
      setIsUploading(false);

      
    } catch (error) {
      setIsUploading(false);
      alert('Upload failed');
    }
  };

  useEffect(() => {
    let textTimer = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % auxiliaryTexts.length);
      setFadeIn(!fadeIn); // toggle fade-in / fade-out
    }, 3500);
  
    return () => {
      if (textTimer) {
        clearInterval(textTimer);
      }
    };
  }, [fadeIn]);

  useEffect(() => {
    setProgressText(auxiliaryTexts[textIndex]);
  }, [textIndex]);

  useEffect(() => {
    // Only run this effect when slowProgress is true
    if (slowProgress) {
      // The 10 here means we want the progress to go up to 80 over 10 seconds
      const step = 80 / 10 / 10; // 10 seconds broken into 10 intervals (0.1s)
      const timer = setInterval(() => {
        setUploadProgress(prev => {
          if (prev < 80) {
            return prev + step;
          } else {
            clearInterval(timer);
            return prev;
          }
        });
      }, 100); // Update every 0.1 second
      return () => {
        clearInterval(timer);
      };
    }
  }, [slowProgress]);
  
  

  return (
    <div className="upload-container1">
      <div className="upload-form-container">
        <h1>Upload A Video</h1>
  
        {!isUploading && (
          <form onSubmit={handleSubmit} className="upload-form">
            <label htmlFor="file-upload" className="custom-file-upload">
              <i className="fas fa-cloud-upload-alt"></i> Choose a file to upload
            </label>
            <input
              type="text"
              className="youtube-link-input"
              placeholder="Or enter a YouTube link"
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
            <input id="file-upload" type="file" ref={fileInput} accept="video/*" onChange={handleFileChange} />
            {selectedFileName && <p className="selected-file-name">{selectedFileName}</p>}
            <button type="submit" className="upload-btn">Upload</button>
          </form>
        )}
  
        {isUploading && (
          <div className="upload-progress-container">
            <div className="upload-progress-bar" style={{ width: `${Math.min(uploadProgress, 90)}%` }}></div>
            <div className={`progress-auxiliary-text ${fadeIn ? 'fade-in' : 'fade-out'}`}>{progressText}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadVideo;