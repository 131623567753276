import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './FeedbackModal.css';
import { saveSurveyResponses } from '../services/api';



function FeedbackModal({ isOpen, onClose, onSaveResponses }) { // onSaveResponses is the new prop we're expecting
  const navigate = useNavigate();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});

  const questions = [
    {
      question: "I am a...",
      options: ["Podcaster", "Content Creator", "Video Editor", "Digital Marketer or Social Media Manager", "Other Professional"],
    },
    {
      question: "I'm excited about...",
      options: ["AI Clip Generation", "Social Media Analytics", "Auto-Posting", "Custom Captions", 'Translation', "Something Else"],
    },
    {
        question: "I post content...",
        options: ["Daily", "Weekly", "Monthly", "Almost Never"],
    }
    // ...other questions
  ];

  const handleOptionClick = (question, option) => {
    setSelectedOptions({
      ...selectedOptions,
      [question]: option,
    });
  };

  const handleNextClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      // Call the submit function here since it's the last question
      handleSubmit();
    }
  };

  // This new function will handle the submission of responses
  const handleSubmit = useCallback(async () => {
    if (typeof onSaveResponses === 'function') {
      onSaveResponses(selectedOptions); // This line is crucial
    }
    // Call the API to save the survey responses
    try {
      const result = await saveSurveyResponses(selectedOptions);
      console.log('Survey submitted:', result);
      // Navigate to the thank you slide or handle the submission success accordingly
      setCurrentQuestionIndex(questions.length);
    } catch (error) {
      console.error('Error submitting survey:', error);
    }
  }, [onSaveResponses, selectedOptions]);
  
  const handleFinalSubmission = useCallback(() => {
    if (typeof onSaveResponses === 'function') {
      console.log("Submitting responses:", selectedOptions); // Add this line
      // Send the responses back to the parent component
      onSaveResponses(selectedOptions); // This line is crucial
    }
  }, [onSaveResponses, selectedOptions]);
  

  const handleUpgradeClick = () => {
    handleFinalSubmission();
    navigate('/profile'); // Navigate to the profile or upgrade page
  };

  const handleCloseSurvey = () => {
    handleFinalSubmission();
    if (typeof onClose === 'function') {
      onClose(); // Trigger the parent's onClose function
    }
  };

  if (!isOpen) return null;

  return (
    <div className="feedback-modal">
      <div className="feedback-content">
        <div className="feedback-intro">
          <h1 className="feedback-intro__title">Hey! 👋 Welcome to EditAir!</h1>
          <h3 className="feedback-intro__subtitle">Please help us by answering a few questions. A special thank you awaits!</h3>
          <p className="feedback-intro__smalltext"></p>
        </div>
        
        <div className="feedback-questions">
          {/* Map through the questions and render them based on the current index */}
          {questions.map((q, index) => (
            <div 
              key={index} 
              className={`feedback-question__slide ${currentQuestionIndex === index ? 'active' : ''}`}
            >
              <h2 className="feedback-question__title">{q.question}</h2>
              <div className="feedback-options">
                {q.options.map((option, i) => (
                  <button
                    key={i}
                    className={`feedback-option ${selectedOptions[q.question] === option ? 'selected' : ''}`}
                    onClick={() => handleOptionClick(q.question, option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
              {/* Only show the 'Next' button if an option has been selected */}
              {selectedOptions[q.question] && (
          <div className="feedback-next-button-wrapper">
            <button className="feedback-next-button" onClick={handleNextClick}>
              {currentQuestionIndex === questions.length - 1 ? 'Submit' : 'Next'}
            </button>
                </div>
              )}
            </div>
          ))}
  
          {/* This is the final 'Thank You' slide which should only be active if all questions have been answered */}
          <div className={`feedback-question__slide ${currentQuestionIndex === questions.length ? 'active' : ''}`}>
            <h2 className="bold-header">Thank You for Using EditAir!</h2>
            <p className="bold-paragraph">We appreciate your time and would like to offer you a special discount.</p>
            <div className="feedback-code-section">
              <span>Use code: </span>
              <code className="feedback-code">THANKYOU25</code>
              <button 
                className="feedback-copy-button" 
                onClick={() => navigator.clipboard.writeText("THANKYOU25")}
              >
                COPY CODE
              </button>
            </div>
            <p className="bold-paragraph">Get 25% off for 3 months on Premium or Creator subscriptions.</p>
            <div className="feedback-button-container">
              <button className="feedback-upgrade-button" onClick={handleUpgradeClick}>Upgrade Now</button>
              <button className="feedback-next-button" onClick={handleCloseSurvey}>Close Survey</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default FeedbackModal;
