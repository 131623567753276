import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import { logout } from '../services/auth';
import { tiktokLogin } from '../services/auth';
import Sidebar from '../components/Sidebar';
import './UserProfile.css'

const UserProfile = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(prevState => !prevState);
  };

  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await logout();

    if (response.result === 'success') {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      console.error(response.message);
    }
  };

  const handleTikTokLogin = async () => {
    const response = await tiktokLogin();
  
    if (response.result === 'success') {
      // Handle a successful TikTok login (e.g., update the app state)
    } else {
      console.error(response.message);
    }
  };
  
  return (
    <div className="profile-container">
      <Sidebar handleLogout={handleLogout} sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle}/>
      <button className="sidebar-toggle" onClick={() => setSidebarOpen(true)}>Menu</button>
      <div className="prof-container">
        <h1>Profile</h1>
        {/* Your profile content here */}
        
      </div>
    </div>
  );
};

export default UserProfile;
