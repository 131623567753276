import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import FontSelector from '../components/FontSelector';
import AuthContext from '../AuthContext';
import ColorPicker from '../components/ColorPicker';
import { generateClips, testClipStatus, checkClipStatus, savePreset, getPresets, deletePreset, getPreviewUrls } from '../services/api';
import { getAccountInfo, getEditedVideos } from '../services/api';
import { logout } from '../services/auth';
import './ClipSettings.css';
import SessionContext from '../SessionContext';
import TopTextPosition from '../components/TopTextPosition';
import MiddleTextPosition from '../components/MiddleTextPosition';
import BottomTextPosition from '../components/BottomTextPosition';
import ChatBot from '../components/ChatBot';
import { AiOutlineClose as CloseIcon, AiOutlineMessage as ChatBubbleIcon } from 'react-icons/ai';
import SettingsAccordion from '../components/SettingsAccordion';

// import blurRata from './featureimages/blurCropRata.png'
// import smartRata from './featureimages/smartCropRata.png'




<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.0/css/all.min.css" />


const ClipSettings = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [clipsGenerated, setClipsGenerated] = useState(false);
  const { sessionId } = useContext(SessionContext);
  const [textStyle, setTextStyle] = useState('multipleWord');
  const [textPosition, setTextPosition] = useState('bottom');
  const [color_palette, setColor] = useState("[(0,10,190), (255,0,0), (0,255,0)]");
  const [textSize, setTextSize] = useState(20)
  const [font, setFont] = useState('Montserrat');
  const [crop, setCrop] = useState("noCrop");
  const [originalLanguage, setOriginalLanguage] = useState('en');
  const [targetLanguage, setTargetLanguage] = useState('en');
  const [showTranslationOptions, setShowTranslationOptions] = useState(false); // New state variable
  const location = useLocation();
  const pathname = window.location.pathname;
  const { videoId } = useParams();
  const videofile = videoId;
  const videoUrl = location.state.videoUrl;
  const navigate = useNavigate();
  const [showChat, setShowChat] = useState(false);
  const [videoVersions, setVideoVersions] = useState([videoUrl]);  // originalVideoURL is the URL of the uploaded video
  const [activeVideo, setActiveVideo] = useState(videoUrl);
  const [activeTab, setActiveTab] = useState('original_0'); 
  const [chatVisible, setChatVisible] = useState(true);
  const [subtitlesEnabled, setSubtitlesEnabled] = useState(false);
  const [cropEnabled, setCropEnabled] = useState(false);
  const [highlight_color, setHighlightColor] = useState('(255,255,0)')
  const [showHighlightColorPicker, setShowHighlightColorPicker] = useState(false);
  const accordionContainerRef = useRef(null);
  const [quality, setSelectedQuality] = useState('original');  // Default to 'original'
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [presetName, setPresetName] = useState('');
  const [savedPresets, setSavedPresets] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [editMode, setEditMode] = useState(true); 
  const [previewUrls, setPreviewUrls] = useState({}); 
  const [accountInfo, setAccountInfo] = useState(null);
  const [currentVideoDuration, setCurrentVideoDuration] = useState(0);
  const [usageLimit, setUsageLimit] = useState(0);
  const videoPreviewRef = useRef(null);
  const imageRef = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);



  
  const cachedUploads = JSON.parse(localStorage.getItem('uploads'));

  const [contentMode, setContentMode] = useState('');

  const handleContentModeChange = (mode) => {
      setContentMode(mode);
      // Any additional logic if needed
  };




  const mapQualityToLabel = (quality) => {
    switch (quality) {
        case '360p': return 'original';
        case '480p': return 'original';
        case '720p': return 'original';
        case '1080p': return 'original';
        default: return 'original';
    }
    };

  const handleQualityChange = (quality) => {
    setSelectedQuality(mapQualityToLabel(quality));
  };


  const handleSubtitlesToggle = () => {
    setSubtitlesEnabled(!subtitlesEnabled);
  };

  const handleCropToggle = () => {
    setCropEnabled(!cropEnabled);
  };


  const [expandedSection, setExpandedSection] = useState("");

    const handleAccordionClick = (sectionTitle) => {
    if (expandedSection === sectionTitle) {
        setExpandedSection(""); // If the current section is clicked again, collapse it
    } else {
        setExpandedSection(sectionTitle); // Otherwise, expand the clicked section
    }
    };

  const maxTabWidth = 150; // Maximum width of a tab, you can adjust
  const minTabWidth = 10;  // Minimum width of a tab, you can adjust

  let tabCount = 1;
  let tabWidth = Math.max(minTabWidth, Math.min(maxTabWidth, 500 / tabCount)); // 1000 is a placeholder for the total available width, adjust as necessary

  const [videoEdits, setVideoEdits] = useState({
    original: [videoUrl],  // assuming you start with only the original video
    // ... other edit types can be initialized as empty arrays or with default values
});


  const toggleChat = () => {
    if (showChat) {
        setChatVisible(false);
        setTimeout(() => {
            setShowChat(false);
        }, 300); // delay to match the transition duration
    } else {
        setShowChat(true);
        setChatVisible(true);
    }
  }


  // useEffect(() => {
  //   const fetchVideos = async () => {
  //     const editedVideos = await getEditedVideos(videofile);
      
  //     // Merge the current state with the new videos from the API
  //     setVideoEdits(prevEdits => ({
  //         ...prevEdits,            // Spread the current state
  //         ...editedVideos          // Spread the new videos from the API, potentially overwriting the current state
  //     }));
  //     tabCount = Object.values(videoEdits).flat().length
  // };
  

  
  //   // Set up the interval
  //   const intervalId = setInterval(fetchVideos, 10000);  // Poll every 10 seconds

  //   // Clear the interval when the component is unmounted
  //   return () => clearInterval(intervalId);
  // }, []);

  // function getTabTitle(editType) {
  //   // Capitalize the first letter of the editType and add "Version" at the end
  //   return `${editType.charAt(0).toUpperCase() + editType.slice(1)} Version`;
  // }


  const languageMap = { // Language to Code mapping
    "English": "en",
    "Spanish": "es",
    "French": "fr",
    "German": "de",
    "Portuguese (PT)" : "pt-PT",
    "Portuguese (BR)" : "pt-BR",
    "Italian" : "it",
    "Chinese" : "zh"
  };

  const formatColors = (colors) => {
    return (
      '[' +
      colors
        .map((color) => `(${color.join(',')})`)
        .join(', ') +
      ']'
    );
  };  

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].length !== arr2[i].length) return false;
      for (let j = 0; j < arr1[i].length; j++) {
        if (arr1[i][j] !== arr2[i][j]) return false;
      }
    }
    return true;
  };
  
  const ColorSelector = ({ selectedColors, onSelect }) => {
    const colorOptions = [
      [[255, 0, 0],[0, 255, 0],[0, 102, 204]],
      [[42,139,44], [204,164,249], [106,34,146]],
      [[148,38,90], [17,159,124], [252,202,22]],
      [[77,12,238], [255,0,103], [0,0,0]]

      // ...add more color options here
    ];


    const handleColorClick = (colors) => {
      onSelect(colors);
    };
  
    return (
      <div className="color-selector">
        {colorOptions.map((colors, index) => (
          <div
            key={index}
            className={`color-option ${arraysEqual(selectedColors, colors) ? 'selected' : ''}`}
            onClick={() => handleColorClick(colors)}
          >
            {colors.map((color, i) => (
              <div
                key={i}
                className="color-box"
                style={{ backgroundColor: `rgb(${color})` }}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const [step, setStep] = useState(0);

  const applyPreset = (presetName) => {
    const preset = savedPresets.find(p => p.preset_name === presetName);
    if (preset) {
      const {
        originalLanguage,
        targetLanguage,
        textStyle,
        textPosition,
        color_palette,
        highlight_color,
        textSize,
        font,
        crop,
        quality,
      } = preset.preset_settings;
      
      setOriginalLanguage(originalLanguage);
      setTargetLanguage(targetLanguage);
      setTextStyle(textStyle);
      setTextPosition(textPosition);
      setColor(color_palette); // Directly set color
      setHighlightColor(highlight_color); // Directly set highlight color
      setTextSize(textSize);
      setFont(font);
      setCrop(crop);
      setSelectedQuality(quality);

      console.log("Applied these colors to the color picker for color and highlight color: ", color_palette, highlight_color);
    }
  };
  
  useEffect(() => {
    if (videoId) {
      if (accountInfo && accountInfo.subscription_type === 'free') {
        setCurrentVideoDuration(15);
      } else {
        const videoInfo = cachedUploads.find(upload => upload.filename === videoId);
        if (videoInfo) {
          setCurrentVideoDuration(videoInfo.duration);
        }
      }
    }
  }, [videoId, accountInfo]); // Added accountInfo as a dependency

  useEffect(() => {
    const fetchPreviewUrls = async () => {
      try {
        const urls = await getPreviewUrls(videofile);

        setPreviewUrls({
          'noCrop': urls[0],
          'blurCrop': urls[1],
          'smartCrop': urls[2]
        });
      } catch (error) {
        console.error("Failed to get preview URLs", error);
      }
    };
  
    fetchPreviewUrls();
  }, [videofile]);
  


  useEffect(() => {
    const fetchClipStatus = async () => {
      const response = await checkClipStatus(videofile);
      if (response.status === 'completed') {
        setClipsGenerated(true);
      }
    };

    fetchClipStatus();
  }, [videofile]);

  const handleLogout = async () => {
    const response = await logout();

    if (response.result === 'success') {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      console.error(response.message);
    }
  };

  function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result 
        ? `[(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})]`
        : null;
  }

  function hexToRgb2(hex) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `(${r}, ${g}, ${b})`;
}

function TextOverlay({ text, textStyle, textPosition, color, highlightColor, textSize, font, videoPreviewRef, imageRef }) {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldStackVertically, setShouldStackVertically] = useState(false);
  let initialX, initialY;
  let offsetX, offsetY; // Declare missing variables
  let fontSize, topValue, bottomValue, displayText, secondWordStyle;
  let boundaryMargin = 0;  // Initialize it here

  useEffect(() => {
    if (textRef.current && imageRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const imageWidth = imageRef.current.offsetWidth;
      boundaryMargin = 0.05 * imageRef.current.offsetWidth;  // Update its value here
  
      if (textWidth > (imageWidth - 2 * boundaryMargin)) {
        console.log("Setting shouldStackVertically to true");
        setShouldStackVertically(true);
      } else {
        console.log("Setting shouldStackVertically to false");
        setShouldStackVertically(false);
      }
    }
  }, [text, textStyle, textPosition, color, highlightColor, textSize, font]);
  
  
  


  // Convert text size to px units

  if (isNaN(textSize) || textSize < 1 || textSize > 65) {
    throw new Error('Invalid textSize value');
  }

  fontSize = `${textSize}px`; // Directly set font size in px


  switch (textPosition) {
    case 'top':
      topValue = '10%';
      break;
    case 'middle':
      topValue = '50%';
      break;
    case 'bottom':
      topValue = '85%'; // Just use top with 90% to place it at the bottom
      break;
    default:
      throw new Error('Invalid textPosition value');
  }

  // Handle text display based on textStyle
  const words = text.split(" ");
  switch (textStyle) {
    case 'singleWord':
      displayText = words[0];
      break;
    case 'multipleWord':
      displayText = text;
      break;
    case 'multipleWordHighlight':
      displayText = null;  // Will handle in JSX
      secondWordStyle = {
        color: highlightColor,
      };
      break;
    default:
      displayText = text;
  }

  const baseStyle = {
    position: 'absolute',
    top: topValue, // Only using top now
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderColor: isHovered ? 'black' : 'transparent', 
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'grab',
    width: 'auto',  
    height: 'auto',
    overflow: 'hidden',
  };

  const innerTextStyle = {
    
    color: color,
    fontSize: fontSize,
    fontFamily: font,
    textAlign: shouldStackVertically ? 'center' : 'initial',
    whiteSpace: shouldStackVertically ? 'pre-wrap' : 'nowrap',  // Use 'pre-wrap' to break and wrap text
    wordWrap: shouldStackVertically ? 'break-word' : 'normal', // Break words if needed
    maxWidth: shouldStackVertically ? (imageRef.current ? `${imageRef.current.offsetWidth - 2 * boundaryMargin}px` : 'auto') : 'auto',
    filter: `drop-shadow(1px 1px 0 black)`,
    WebkitTextStroke: '1.5px black',
    padding: '0 0%',
    transition: 'font-size 0.5s ease',
    overflow: 'hidden',
  };
  

  return (
    <div
      ref={containerRef}
      style={baseStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div ref={textRef} style={innerTextStyle}>

        {textStyle === 'multipleWordHighlight' ? (
          <>
            {words[0]} <span style={secondWordStyle}>{words[1]}</span> {words.slice(2).join(" ")}
          </>
        ) : (
          displayText
        )}
      </div>
    </div>
  );
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedColors = hexToRgb(color_palette);
      const formattedHighlight = hexToRgb2(highlight_color);
      const response = await generateClips(videofile, {originalLanguage, targetLanguage, textStyle, textPosition, color_palette: formattedColors, highlight_color: formattedHighlight, textSize, font, crop, quality }, sessionId);
      if (response.result === 'success') {
        navigate(`/processing/${videofile}`, { state: { videofile } });
      } else {
        alert(`Clip generation failed: ${response.message}`);
      }
    } catch (error) {
      alert('Clip generation failed!!');
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const response = await getAccountInfo();
        if (response.result === 'success') {

          console.log("Account info: ", response.accountInfo);
          setAccountInfo(response.accountInfo);

          switch (response.accountInfo.subscription_type) {
            case 'free':
              setUsageLimit(120);
              break;
            case 'prod_O8wYwjy7Op6eSU':
              setUsageLimit(300);
              break;
            case 'prod_O8wcbG8gumzDs1':
              setUsageLimit(1440);
              break;
            default:
              setUsageLimit(0);
          }
        }
      })();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchPresets = async () => {
      const fetchedData = await getPresets();  //  getPresets is the frontend function to fetch all presets

      
      if (fetchedData.result === 'success') {
        setSavedPresets(fetchedData.presets);
      }
    };
    
    fetchPresets();
  }, []);
  
  
  useEffect(() => {
    const fetchPresets = async () => {
      const fetchedData = await getPresets();
      if (fetchedData.result === 'success') {
        console.log("Fetched presets: ", fetchedData.presets)
        setSavedPresets(fetchedData.presets);
      }
    };
  
    fetchPresets();
  }, [savedPresets]);  // Re-run useEffect whenever savedPresets changes
  
  const handleSavePreset = async () => {
    const settings = {
      originalLanguage,
      targetLanguage,
      textStyle,
      textPosition,
      color_palette, // No need to format
      highlight_color, // No need to format
      textSize,
      font,
      crop,
      quality,
    };
    
    const saveResult = await savePreset(presetName, settings); 
  
    if (saveResult.result === 'success') {
      setSavedPresets([...savedPresets, {preset_name: presetName, preset_settings: settings}]);
    }
    setPresetName('');
    setShowPopup(false);
  };
  
  

  const handleOriginalLanguageChange = (event) => {
    const newOriginalLanguage = event.target.value;
    setOriginalLanguage(newOriginalLanguage);
  
    // If translation is off, update the target language to match the original language
    if (!showTranslationOptions) {
      setTargetLanguage(newOriginalLanguage);
    }
  };
  

  const handleTargetLanguageChange = (e) => {
    const languageName = e.target.value;
    const languageCode = languageMap[languageName];
    setTargetLanguage(languageCode); // set state variable to language code instead of language name
  };

  const handleToggleChange = () => {
    setShowTranslationOptions(!showTranslationOptions);
    if(showTranslationOptions){
        // If the translation is being toggled off, set the target language to the original language
        setTargetLanguage(originalLanguage);
    }
};
/*
    
  );
}; */
const willExceedLimit = accountInfo 
? accountInfo.monthly_minutes_processed + currentVideoDuration > usageLimit 
: true;



return (
  <div className="clip-settings">
    <div className="purple-square-background"></div>
    <div className="clip-settings-content">
      <div className="tab-container">
        <div className="tab-bar">
          {/* Add new tab for switching between Original and Edit Mode */}
          <div 
            className={`tab ${!editMode ? 'active' : ''}`}
            onClick={() => setEditMode(false)}
          >
            Original Video
          </div>
          <div 
            className={`tab ${editMode ? 'active' : ''}`}
            onClick={() => setEditMode(true)}
          >
            Preview
          </div>

          {/* Existing tabs */}
          {/* {Object.entries(videoEdits).map(([editType, videoURLs]) => 
            videoURLs.map((videoURL, index) => (
                <div 
                    key={`${editType}_${index}`} 
                    className={`tab ${activeTab === `${editType}_${index}` ? 'active' : ''}`}
                    onClick={() => {
                        setActiveTab(`${editType}_${index}`);
                        setActiveVideo(videoURL);
                    }}
                >
                    {getTabTitle(editType)}
                </div>
            ))
          )} */}
        </div>

        <div className="video-container" ref={videoPreviewRef} style={{ position: 'relative' }}>
    {editMode ? (
      <>
        {!isImageLoaded && <div className="loading-spinner"></div>}
        <img
          ref={imageRef}
          src={previewUrls[crop]}
          alt="Preview"
          className="video-preview"
          onLoad={() => setIsImageLoaded(true)}
        />
        {isImageLoaded && (
          <TextOverlay
            videoPreviewRef={videoPreviewRef}
            imageRef={imageRef}
            text="Your captions will go here"
            textStyle={textStyle}
            textPosition={textPosition}
            color={color_palette}
            highlightColor={highlight_color}
            textSize={textSize}
            font={font}
          />
        )}
      </>
    ) : (
    <video controls key={activeVideo} className="video-element">
      <source src={activeVideo} type="video/mp4" />
    </video>
  )}
</div>
</div>

        <div className="form-container">
        {!showChat && (
            <>
                <div className="header-container">
                <h4 className='step-heading'>Select your clips style preferences below:</h4>
                <div className="preset-container">
                  <button 
                    className="preset-button" 
                    onClick={() => setShowDropdown(!showDropdown)}
                    style={{borderRadius: showDropdown ? '5px 5px 0 0' : '5px'}}
                  >
                    Saved Presets ▼
                  </button>
                  {showDropdown && (
                    <div className="preset-dropdown" style={{ backgroundColor: 'rgb(80, 10, 90)', color: 'white' }}>
                      <ul>
                        {savedPresets.map((preset, index) => (
                          <li key={index} onClick={() => applyPreset(preset.preset_name)}>
                            {preset.preset_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                </div>
              </div>
                <div className="accordion-container" ref={accordionContainerRef}>
                <SettingsAccordion 
                    title="Language"
                    isActive={expandedSection === "Language"}
                    onHeaderClick={() => handleAccordionClick("Language")}
                    accordionContainerRef={accordionContainerRef}
                >
                    <div className="language-section">

                        
                        <p className="step-subheading">Choose the original video language.</p>
                        
                        <div className="language-selector">
                            <div className="select-group">
                                <select 
                                    id="original-language" 
                                    value={originalLanguage} 
                                    onChange={handleOriginalLanguageChange}
                                >
                                    {Object.keys(languageMap).map((language) => (
                                        <option key={language} value={languageMap[language]}>
                                            {language}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="translation-options">
                                <h4 className='step-heading'>Translation Settings</h4>
                                <p className="step-subheading">When translation is off, subtitles will be in video language.</p>
                                <label className="switch">
                                    <input type="checkbox" onChange={handleToggleChange} />
                                    <span className="slider round"></span>
                                </label>
                                {showTranslationOptions && (
                                    <div className="select-group">
                                        <select
                                            id="target-language"
                                            value={targetLanguage}
                                            onChange={handleTargetLanguageChange}
                                        >
                                            {['English'].map((language) => (
                                                <option key={language} value={language}>
                                                    {language}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </SettingsAccordion>

                <SettingsAccordion 
                    title={
                        <div 
                            onMouseEnter={() => setTooltipVisible(true)}
                            onMouseLeave={() => setTooltipVisible(false)}
                            className="tooltipContainer"
                        >
                            Content Mode
                            {isTooltipVisible && <span className="customTooltip">Choose a style of content that fits your video best. <br /> This will be used to provide a unique AI clipping algorithm that best fits your content.</span>}
                        </div>
                    }
                    isActive={expandedSection === "Content Mode"}
                    onHeaderClick={() => handleAccordionClick("Content Mode")}
                    accordionContainerRef={accordionContainerRef}
                >
                    <p className="step-subheading">Select the segment style of your returned clips.</p>
                    <div className='content-mode-options'>
                        <select defaultValue="default" onChange={(e) => handleContentModeChange(e.target.value)}>
                            <option value="default" disabled>Select Content Mode...</option>
                            <option value="general">General</option>
                            <option value="podcasts">Podcasts</option>
                            <option value="tv_show_movie">TV Shows/Movies</option>
                            <option value="sports_highlights">Sports Highlights</option>
                        </select>
                    </div>
                </SettingsAccordion>


                <SettingsAccordion 
                    title="Crop"
                    isActive={expandedSection === "Crop"}
                    onHeaderClick={() => handleAccordionClick("Crop")}
                    
                    onToggleChange={handleCropToggle}
                    toggleState={cropEnabled}
                    accordionContainerRef={accordionContainerRef}
                >
                    <p className="step-subheading">Select the crop mode you'd like to use for your video. Smart Crop and Blur Crop will generate your clips for 9:16 aspect ratio (mobile sizing).</p>
                    <div className="style-selector">
                        <button 
                        type = "button"
                        className={`crop-mode-btn ${crop === 'noCrop' ? 'selected' : ''}`}
                        onClick={() => setCrop('noCrop')}>
                        No Crop
                        </button>
                        <div className="crop-mode-container">
                          <button 
                            type="button"
                            className={`crop-mode-btn ${crop === 'smartCrop' ? 'selected' : ''}`}
                            onMouseEnter={() => setHoveredButton('smartCrop')}
                            onMouseLeave={() => setHoveredButton(null)}
                            onClick={() => setCrop('smartCrop')}
                          >
                            Smart Crop
                          </button>
                          {hoveredButton === 'smartCrop' && (
                            <div className="hover-image-container">
                              {/* <img src={smartRata} alt="Smart Crop Example" /> */}
                            </div>
                          )}
                        </div>

                        <div className="crop-mode-container">
                          <button 
                            type="button"
                            className={`crop-mode-btn ${crop === 'blurCrop' ? 'selected' : ''}`}
                            onMouseEnter={() => setHoveredButton('blurCrop')}
                            onMouseLeave={() => setHoveredButton(null)}
                            onClick={() => setCrop('blurCrop')}
                          >
                            Blur Crop
                          </button>
                          {hoveredButton === 'blurCrop' && (
                            <div className="hover-image-container">
                              {/* <img src={blurRata} alt="Blur Crop Example" /> */}
                            </div>
                          )}
                        </div>

                    </div>
                </SettingsAccordion>

                <SettingsAccordion 
                    title="Captions"
                    isActive={expandedSection === "Subtitles"}
                    onHeaderClick={() => handleAccordionClick("Subtitles")}
                    showToggle={true}
                    onToggleChange={handleSubtitlesToggle}
                    toggleState={subtitlesEnabled}
                    accordionContainerRef={accordionContainerRef}
                >
                   <div className='subtitle-content'>
                   <div className="control-bar">
                        {/* Font Selector */}
                        <div className="control-item">
                            <label className='step-heading'>Font</label>
                            <FontSelector
                                selectedFont={font}
                                onSelect={(font) => setFont(font)}
                            />
                        </div>

                        {/* Text Size Selector */}
                        <div className="control-item">
                          <label className='step-heading'>Size</label>
                          <input className='text-size-slider'
                              type="range"
                              min="1"
                              max="65"
                              value={textSize}
                              onChange={(e) => setTextSize(e.target.value)}
                          />
                          <span>{`${textSize}px`}</span>
                          
                        </div>
                    </div>
                    <div className="form-group-special">
                        <div className="form-group-left">
                            <h4 className='step-heading'>Text Color</h4>
                            <p className="step-subheading">Select the color you'd like to use for your captions.</p>
                        </div>
                        <div className="form-group-right">
                            <ColorPicker color={color_palette} setColor={setColor} />
                        </div>
                    </div>
                       <div className="text-style-section">
                        <h4 className='step-heading'>Style & Position</h4>
                        <p className="step-subheading">
                            With Highlight on, the current word spoken will be highlighted.
                        </p>
                        <div className="caption-control-bar">
                            <div 
                                className={`caption-control-item ${textStyle === 'singleWord' ? 'selected' : ''}${showTranslationOptions ? 'disabled' : ''}`} 
                                onClick={() => {
                                    setTextStyle('singleWord');
                                    setShowHighlightColorPicker(false);
                                }}
                            >
                                <button type="button" className="text-style-btn">Single Word</button>
                            </div>
                            
                            <div 
                                className={`caption-control-item ${textStyle === 'multipleWord' ? 'selected' : ''}`} 
                                onClick={() => {
                                    setTextStyle('multipleWord');
                                    setShowHighlightColorPicker(false);
                                }}
                            >
                                <button type="button" className="text-style-btn">Multiple Word</button>
                            </div>

                            <div 
                                className={`caption-control-item ${textStyle === 'multipleWordHighlight' ? 'selected' : ''}${showTranslationOptions ? 'disabled' : ''}`} 
                                onClick={() => {
                                    setTextStyle('multipleWordHighlight');
                                    setShowHighlightColorPicker(true);
                                }}
                            >
                                <button type="button" className="text-style-btn">Multiple Word With Highlight</button>
                            </div>
                            </div>

                            {showHighlightColorPicker && (
                                <div className="form-group-special">
                                    <div className='form-group-left'>
                                    <label>
                                        <h4 className='step-heading'>Highlight Color{" "}</h4>
                                        <span className="tooltip">
                                            <i className="fas fa-question-circle"></i>
                                            <span className="tooltip-text">
                                                Note: This will be the highlight color for the current word being spoken. 
                                            </span>
                                        </span>
                                    </label>
                                    <p className="step-subheading">Select the color you'd like to use for word highlight.</p>
                                    </div>
                                    <div className='form-group-right'>
                                    <ColorPicker color={highlight_color} setColor={setHighlightColor} />
                                    </div>
                                </div>
                            )}

                        {textStyle && (
                        <>
                        
                        <div className="position-control-bar">
                            <div 
                                className={`position-control-item ${textPosition === "top" ? "selected-svg" : ""}`} 
                                onClick={() => setTextPosition("top")}
                            >
                                <TopTextPosition />
                                <p className="svg-description">Top</p>
                            </div>

                            <div 
                                className={`position-control-item ${textPosition === "middle" ? "selected-svg" : ""}`} 
                                onClick={() => setTextPosition("middle")}
                            >
                                <MiddleTextPosition />
                                <p className="svg-description">Middle</p>
                            </div>

                            <div 
                                className={`position-control-item ${textPosition === "bottom" ? "selected-svg" : ""}`} 
                                onClick={() => setTextPosition("bottom")}
                            >
                                <BottomTextPosition />
                                <p className="svg-description">Bottom</p>
                            </div>
                            </div>
                        </>
                    )}                      
                    </div>
                   </div>
                </SettingsAccordion>


                

                {/*
                <SettingsAccordion 
                    title="Watermark (Coming Soon)"
                    isActive={expandedSection === "Watermark (Coming Soon)"}
                    onHeaderClick={() => handleAccordionClick("Watermark (Coming Soon)")}
                    accordionContainerRef={accordionContainerRef}
                >
                    
                </SettingsAccordion> */}

                

                <SettingsAccordion 
                    title="Auto-Posting (Coming Soon)"
                    isActive={expandedSection === "Auto-Posting (Coming Soon)"}
                    onHeaderClick={() => handleAccordionClick("Auto-Posting (Coming Soon)")}
                    accordionContainerRef={accordionContainerRef}
                ></SettingsAccordion>


                </div>

                {isLoggedIn && accountInfo && usageLimit > 0 ? (
                    <div className='bottom-btns'>
                    <label className="preset-save-label">
                    <input 
                      type="checkbox" 
                      className="preset-save-checkbox"
                      id="save-preset-checkbox" 
                      checked={isChecked}
                      onChange={() => {
                        setIsChecked(!isChecked);
                        if (!isChecked) {
                          setShowPopup(true);
                        }
                      }}
    
                    />
                    Save this configuration to presets
                  </label>
                  {showPopup && (
                  <div className="preset-popup">
                    <h3 className="preset-popup-header">Give your preset a name...</h3>
                    <button 
                      className="close-preset-name" 
                      onClick={() => {
                        setShowPopup(false);
                        setIsChecked(false);
                      }}
                    >
                      X
                    </button>
                    <input 
                      className="preset-input"
                      type="text" 
                      value={presetName}
                      onChange={(e) => setPresetName(e.target.value)}
                      placeholder="Preset Name"
                    />
                    <button className="preset-confirm-button" onClick={handleSavePreset}>
                      Confirm
                    </button>
                  </div>
                )}
                  <button 
                    type="submit" 
                    className="generate-clips-btn"
                    disabled={willExceedLimit || !accountInfo}
                    onClick={handleSubmit}
                  >
                    Generate Clips
                  </button>

                 
                  {willExceedLimit && accountInfo && (
                  <p className="limit-message">
                    You do not have enough minutes remaining to process this video.  <Link to="/subscription">Upgrade now.</Link>
                  </p>
                )}

                {!willExceedLimit && accountInfo?.monthly_minutes_processed >= usageLimit && (
                  <p className="limit-message">
                    You have reached your usage limit. <Link to="/subscription">Upgrade now.</Link>
                  </p>
                )}
                    <button 
                        type="button" 
                        className="chat-btn" 
                        onClick={toggleChat}
                        disabled={accountInfo.subscription_type !== 'prod_O8wcbG8gumzDs1'}
                    >
                        Chat with Eddie AI
                    </button>


                    </div>
                    ) : null}
            </>
        )}

        {showChat && ( 
        <>
        <div className="chat-header">
            <h2 className="eddie-ai-title">EDDIE AI</h2>
            <button type="button" className="close-btn" onClick={toggleChat}>
                <CloseIcon size={15} />
            </button>
            
            
        </div>
            <ChatBot visible={chatVisible} videofile={videofile} />
        </>
        )}
          {clipsGenerated ? (
            <>
              <Link
                to={{
                  pathname: `/clips/${videofile}`,
                  state: { videofile },
                }}
                className="view-generated-clips-btn"
              >
                View Generated Clips
              </Link>
              <button
                type="button"
                className="regenerate-clips-btn"
                onClick={() => setClipsGenerated(false)}
              >
                Regenerate Clips
              </button>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <div
                className="form-group-container"
                style={{
                  transform: `translateX(-${step * 14.29}%)`,
                  transition: "transform 0.5s ease-in-out",
                }}
              >
            
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClipSettings;
