import React from 'react';
import './FontSelector.css';

const FontSelector = ({ selectedFont, onSelect }) => {
  const fonts = [
    'Montserrat',
    'AppleTea',
    'Kaph',
    'Poppin',
    'PTF',
    'PoetsenOne',
    'PeaceSans'
  ];

  return (
    <div className="font-selector">
      <select
        value={selectedFont}
        onChange={(e) => onSelect(e.target.value)}
        style={{ fontFamily: selectedFont }}
      >
        {fonts.map((font, index) => (
          <option key={index} value={font} style={{ fontFamily: font }}>
            {font}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FontSelector;
