import React from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import googleButton from './google_button.png'; // Make sure the path is correct
import googleButton2 from './google_signin.png'; // Make sure the path is correct

const GoogleSignIn = ({ onSignIn }) => {
  const handleGoogleSignIn = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      onSignIn(user);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <img 
      src={googleButton2} 
      alt="Google Sign-In" 
      onClick={handleGoogleSignIn} 
      style={{ cursor: 'pointer', width: '200px' }} // Adjust width as needed
    />
  );
};

export default GoogleSignIn;
