import React, { useState, useEffect } from "react";
import axios from "axios";
import './TiktokUpload.css';
import { trackTikTokUpload } from '../services/api';
import Confetti from 'react-confetti';

// Creator info data needs to be rendered in UI
// "creator_avatar_url": "https://lf16-tt4d.tiktokcdn.com/obj/tiktok-open-platform/8d5740ac3844be417beeacd0df75aef1",
// "creator_username": "tiktok",
// "creator_nickname": "TikTok Official",
// "privacy_level_options": ["PUBLIC_TO_EVERYONE", "MUTUAL_FOLLOW_FRIENDS", "SELF_ONLY"]
// "comment_disabled": false,
// "duet_disabled": false,
// "stitch_disabled": true,
// "max_video_post_duration_sec": 300

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TiktokUpload = (props) => {
  // Creator info needed for rending
  const [avatarUrl, setAvatarUrl] = useState("");
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [privacyLevelOps, setPrivacyLevelOps] = useState([]);
  const [commentDisabled, setCommentDisabled] = useState(false);
  const [duetDisabled, setDuetDisabled] = useState(false);
  const [stitchDisabled, setStitchDisabled] = useState(false);
  const [maxVideoDuration, setMaxVideoDuration] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [showTikTokConfetti, setShowTikTokConfetti] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(true);  // Set it to true initially since the content will be loading when the component mounts.



  const privacyLevelMapping = {
    'PUBLIC_TO_EVERYONE': 'Everyone',
    'MUTUAL_FOLLOW_FRIENDS': 'Friends',
    'SELF_ONLY': 'Only me',
    'FOLLOWER_OF_CREATOR': 'Followers',
  };

  // Trimmed Clip information
  const videourl = props.videourl;
  const start_time = props.start;
  const stop_time = props.stop;
  const title = props.title;
  const videofile = props.videofile;


  // form data stored in these state variables
  const [privacy_level, setPrivacyLevel] = useState("PUBLIC_TO_EVERYONE");
  const [allowComments, setAllowComments] = useState(true);
  const [allowDuet, setAllowDuet] = useState(false);
  const [allowStitch, setAllowStitch] = useState(false);

  // axios.post(/tiktok-upload, (videoUrl, trim[0], trim[1]))

  useEffect(() => {
    const session = localStorage.getItem("session");
    const config = {
      headers: {
        session: session,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/tiktok-creator-info`, config)
      .then((response) => {
        setAvatarUrl(response.data.creator_avatar_url);
        setUsername(response.data.creator_username);
        setNickname(response.data.creator_nickname);
        setPrivacyLevelOps(response.data.privacy_level_options);
        setCommentDisabled(response.data.comment_disabled);
        setAllowDuet(response.data.duet_disabled);
        setAllowStitch(response.data.stitch_disabled);
        setMaxVideoDuration(response.data.max_video_post_duration_sec);
        
        // Set loading to false once data is fetched
        setIsAccountLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching TikTok creator info:", error);
        // Handle error accordingly, maybe even update the state to reflect an error state.
        setIsAccountLoading(false);  // Still set to false to stop the spinner, but handle the error appropriately.
      });
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    // Prepare the data to send to the backend
    const formData = {
      videourl,
      start_time,
      stop_time,
      privacy_level,
      disable_comment: !allowComments,
      disable_duet: !allowDuet,
      disable_stitch: !allowStitch,
      title,
    };
    console.log(formData);

  
    const session = localStorage.getItem("session");
  
    fetch(`${API_BASE_URL}/api/tiktok-upload`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        session: session,
      },
      body: JSON.stringify(formData),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();  // we only proceed if the status was 200
    })
    .then(data => {
      // Assuming the response contains some form of data confirmation
      console.log('TikTok upload successful:', data);
  
      // Now we track the TikTok upload
      return trackTikTokUpload(videofile, start_time, stop_time); // this function should return a Promise

    })
    .then(trackResponse => {
      console.log('TikTok upload tracking response:', trackResponse);
  
      // Check if the tracking was successful and handle the UI accordingly
      if (trackResponse.result === 'success') {
        setShowTikTokConfetti(true);
        setTimeout(() => setShowTikTokConfetti(false), 5000); // hide confetti after 5 seconds
      } else {
        // Handle the scenario when tracking was not successful
        console.error('TikTok upload tracking was not successful:', trackResponse.message);
      }
    })
    .catch(error => {
      // This block catches the error in the entire promise chain
      console.error('An error occurred:', error);
    })
    .finally(() => {
      setIsLoading(false);  // We make sure to not leave the user hanging
    });
  };
  
  


  return (
    <>
    {isAccountLoading ? (
      <div className="loading-container">
        <div className="spinner-tok"></div>
        <p>Loading...</p>
      </div>
    ) : (
      <>
        {showTikTokConfetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            colors={["#420c68", "#800080", "#c19cdb", "#000000"]}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div className="tiktok">
            <img src={avatarUrl} alt="avatar" />
            <div>
              <p>{username}</p>
              <h4>{nickname}</h4>
            </div>
          </div>
          <div>
            <label>Privacy Level:</label>
            <select value={privacy_level} onChange={(e) => setPrivacyLevel(e.target.value)}>
              {privacyLevelOps.map((privacyOption) => (
                <option key={privacyOption} value={privacyOption}>
                  {privacyLevelMapping[privacyOption] || privacyOption} {/* Fallback to privacyOption if not in mapping */}
                </option>
              ))}
            </select>
          </div>
          <div className="option">
            <div>
              <label>Comments</label>
              <input
                type="checkbox"
                checked={allowComments}
                onChange={(e) => setAllowComments(e.target.checked)}
                disabled={commentDisabled}
              />
            </div>
    
            <div>
              <label>Duet</label>
              <input
                type="checkbox"
                checked={allowDuet}
                onChange={(e) => setAllowDuet(e.target.checked)}
                disabled={duetDisabled}
              />
            </div>
    
            <div>
              <label>Stitch</label>
              <input
                type="checkbox"
                checked={allowStitch}
                onChange={(e) => setAllowStitch(e.target.checked)}
                disabled={stitchDisabled}
              />
            </div>
          </div>
          <button className='tiktokbtn' type="submit" disabled={isLoading}>
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Posting...
              </>
            ) : (
              "Post"
            )}
          </button>
        </form>
      </>
    )}
    </>
  );
  
  
};

export default TiktokUpload;
