import React, { useEffect, useRef } from 'react';
import './StripeContainer.css';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;


const StripeContainer = () => {
  const stripeRef = useRef(null);

  useEffect(() => {
    if (!window.customElements.get('stripe-pricing-table')) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="stripe-container">
      <stripe-pricing-table
        ref={stripeRef}
        pricing-table-id="prctbl_1NlyF4BGlMH5hn8UBVsbDbUm"
        publishable-key={STRIPE_KEY}
      ></stripe-pricing-table>
    </div>
  );
};

export default StripeContainer;
