import React, { useEffect } from 'react';
import './SettingsAccordion.css';

const SettingsAccordion = ({ title, isActive, onHeaderClick, children, showToggle, onToggleChange, toggleState, accordionContainerRef }) => {

    useEffect(() => {
        if (isActive && accordionContainerRef.current) {
            const container = accordionContainerRef.current;
            const accordionTop = container.offsetTop;
            container.scrollTop = accordionTop;
        }
    }, [isActive, accordionContainerRef]);

    return (
        <div className={`AccordionWrapper ${isActive ? 'active' : ''}`}>
            <div className="AccordionHeader" onClick={onHeaderClick}>
                <div className="header-content">
                    <span>{title}</span>
                    {(title === "Subtitles"  || title ===  "Crop") && showToggle &&
                        <label className="switch">
                            <input type="checkbox" checked={toggleState} onChange={onToggleChange} />
                            <span className="slider round"></span>
                        </label>
                    }
                </div>
            </div>
            <div className={`AccordionContent ${isActive ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    );
}

export default SettingsAccordion;
