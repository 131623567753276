import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { forgotPassword } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const validateForm = () => {
    let regex = /\S+@\S+\.\S+/;
    if (!regex.test(email)) {
      setMessage("Invalid email format");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const response = await forgotPassword(email);
      if (response.result === 'success') {
        setMessage('Password reset email sent successfully');
        navigate('/login');
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      setMessage("Something went wrong");
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form-container">
        <h1>Forgot Password</h1>
        <form onSubmit={handleSubmit} className="forgot-password-form">
          <label htmlFor="email" className="forgot-password-label">Email:</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="forgot-password-input"
          />
          <button type="submit" className="forgot-password-button">Reset Password</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default ForgotPassword;
